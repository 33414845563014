import React from "react";
import { BrowserRouter } from "react-router-dom";
import "./app.scss";
import "./index.css";
import RoutesComponent from "./routes/RoutesComponent";
import ScrollToTop from "./components/ScrollToTop";
import StyleWrapper from "./components/StyleWrapper";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <StyleWrapper>
        <RoutesComponent />
      </StyleWrapper>
    </BrowserRouter>
  );
}

export default App;
