import React, { useState } from "react";

const FloatingInput = ({
  id,
  type = "text",
  label,
  value,
  onChange,
  required = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <input
        id={id}
        type={type}
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className="w-full h-14 px-4 pt-4 pb-1 text-gray-700 bg-white border rounded-lg focus:outline-none focus:ring-1 focus:ring-blue-500 peer"
        placeholder=" "
      />
      <label
        htmlFor={id}
        className={`absolute text-sm duration-150 transform -translate-y-1.5 top-2.5 left-4 origin-[0] 
            peer-placeholder-shown:translate-y-0 
            peer-focus:-translate-y-1.5
            ${isFocused || value ? "text-xs text-blue-600" : "text-gray-500"}
            ${
              required
                ? 'after:content-["*"] after:ml-0.5 after:text-red-500'
                : ""
            }`}
      >
        {label}
      </label>
    </div>
  );
};

export default FloatingInput;
