import React, { useState } from "react";
import tinnImg from "../../assets/admin/img/signFinal.jpg";
import { Link } from "react-router-dom";
import { ADMIN_ROUTES } from "../../constants/RouteConst";
import { useSendPasswordResetEmail } from "react-firebase-hooks/auth";
import { auth } from "../../common/firebase";
import { checkEmailExists } from "../../common/helper";
import Alert from "../../components/alert/Alert";

const Forget = () => {
  const [sendPasswordResetEmail, , error] = useSendPasswordResetEmail(auth);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const handleForgetPassword = async (e) => {
    e.preventDefault();
    try {
      setSuccessMsg("");
      setErrorMsg("");
      const email = e.target.email.value;
      const emailExists = await checkEmailExists(email);
      if (emailExists) {
        await sendPasswordResetEmail(email)
          .then(() => {
            setSuccessMsg("Password reset email sent!");
          })
          .catch((err) => {
            setErrorMsg(error);
          });
      } else {
        setErrorMsg("Email does not exist in our database");
      }
    } catch (error) {
      setErrorMsg("Authentication failed: " + error.message);
    }
  };

  return (
    <div className="authpages h-screen overflow-y-auto">
      <div className="container-fluid h-full signupSection forgetpassword">
        <div className="flex h-full items-center justify-center lg:items-center">
          <div className="hidden lg:block w-1/2 overflow-hidden">
            <div className="registerImg">
              <img
                src={tinnImg}
                className="h-full w-full object-cover"
                alt="table accordion"
              />
            </div>
          </div>
          <div className="w-full px-4 sm:w-4/5 lg:w-5/12 xl:w-4/12">
            <div className="inputsignSide py-4">
              <div className="mx-auto max-w-md space-y-6">
                <div>
                  <h1 className="headingI text-3xl font-normal text-gray-800 text-center">
                    Forget Password
                  </h1>
                </div>
                <div>
                  <p className="subHeding text-base text-gray-600 opacity-60 line-clamp-2 text-center">
                    Sign in to continue to Neuromonics.
                  </p>
                </div>
                <form
                  className="MainForm space-y-6"
                  onSubmit={handleForgetPassword}
                >
                  {errorMsg && <Alert text={errorMsg} type="error" />}
                  {successMsg && <Alert text={successMsg} type="success" />}

                  <div className="relative">
                    <input
                      type="email"
                      className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                      placeholder-transparent focus:border-theme2 focus:outline-none"
                      name="email"
                      id="floatingInput"
                      placeholder="Email address"
                    />
                    <label
                      htmlFor="floatingInput"
                      className="absolute left-5 top-1/2 text-start -translate-y-1/2 text-gray-500 transition-all
                      peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                      peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                      peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                    >
                      <div className="labelTxt">Email address</div>
                    </label>
                  </div>

                  <div>
                    <button
                      className="signinBtn w-full rounded-full bg-theme2 px-6 py-3 text-sm font-medium text-white transition-all duration-300 hover:opacity-90"
                      type="submit"
                    >
                      Reset Password
                    </button>
                  </div>

                  <div className="text-center">
                    <Link
                      to={ADMIN_ROUTES.LOGIN_URL}
                      className="forgottxt text-theme2 font-semibold text-sm no-underline hover:opacity-80"
                    >
                      Back To Sign In?
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forget;
