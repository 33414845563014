import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import Loader from "../../extra/Loader";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import Footer from "../../../components/frontendComponents/footer/Footer";

const SingleJournal = () => {
  const scrollContainer = useRef(null);
  const slug = useParams();
  const [jsonData, setJsonData] = useState(null);
  const [getMainCategory, setGetMainCategory] = useState(null);
  const [getSubCategory, setGetSubCategory] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.JOURNALS + "/" + FILENAME.JOURNALSFILE
      );
      try {
        const url = await getDownloadURL(jsonFileRef);
        const response = await fetch(url);
        const data = await response.json();
        const categories = data.categories;
        const result = findItemBySlug(categories, slug.slug);
        if (result) {
          const { category, subcategory, item } = result;
          setGetMainCategory(category);
          setGetSubCategory(subcategory);
          setJsonData(item);
        } else {
          navigator("/404");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, [navigator, slug.slug]);

  const findItemBySlug = (categories, slug) => {
    for (const category in categories) {
      if (categories.hasOwnProperty(category)) {
        const categoryItems = categories[category];

        if (Array.isArray(categoryItems) && categoryItems.length > 0) {
          const indexOfObject = categoryItems.findIndex(
            (item) => item.slug === slug
          );
          if (indexOfObject !== -1) {
            return {
              category,
              subcategory: "",
              item: categoryItems[indexOfObject],
            };
          }
        } else if (typeof categoryItems === "object") {
          for (const subCategory in categoryItems) {
            if (categoryItems.hasOwnProperty(subCategory)) {
              const subCategoryItems = categoryItems[subCategory];

              if (
                Array.isArray(subCategoryItems) &&
                subCategoryItems.length > 0
              ) {
                const indexOfObject = subCategoryItems.findIndex(
                  (item) => item.slug === slug
                );
                if (indexOfObject !== -1) {
                  return {
                    category: category,
                    subcategory: subCategory,
                    item: subCategoryItems[indexOfObject],
                  };
                }
              }
            }
          }
        }
      }
    }

    return null;
  };

  return (
    <div className="min-h-screen bg-gray-50" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />

      <div className="w-full pt-16 pb-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Banner */}
          <div className="mb-8">
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-blue-800">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
              </svg>
              <span className="font-medium">Neuromonics Resources</span>
            </div>
          </div>

          {isLoading && <Loader />}

          {!isLoading && jsonData && (
            <div className="space-y-6">
              {/* Category Headers */}
              <div className="space-y-2">
                <h1 className="text-2xl font-bold text-gray-900">
                  {getMainCategory}
                </h1>
                {getSubCategory && (
                  <h2 className="text-xl font-semibold text-gray-700">
                    {getSubCategory}
                  </h2>
                )}
              </div>

              {/* Journal Card */}
              <div className="w-full md:w-2/3 lg:w-1/2">
                <div className="bg-white rounded-lg shadow-md p-6">
                  <div className="flex flex-col h-full">
                    <div className="mb-6">
                      <h3 className="text-lg font-semibold">
                        <Link
                          to={jsonData.link}
                          target="_blank"
                          className="text-blue-600 hover:text-blue-800 transition-colors"
                        >
                          {jsonData.title}
                        </Link>
                      </h3>
                    </div>

                    <div className="mt-auto space-y-3">
                      <div className="text-sm text-gray-600">
                        {jsonData.citation}
                      </div>
                      <div className="text-sm text-gray-600">
                        {jsonData.authors.join(", ")}
                      </div>
                      {/* Uncomment if download link is needed
                                            <div>
                                                <Link 
                                                    to={jsonData.slug} 
                                                    target="_blank"
                                                    className="text-sm text-gray-700 hover:text-gray-900"
                                                >
                                                    Download
                                                </Link>
                                            </div>
                                            */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default SingleJournal;
