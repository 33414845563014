import React, { useEffect, useState } from "react";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../common/firebase";
import Loader from "../extra/Loader";
// import { Link, useNavigate } from "react-router-dom";
import { FILENAME, STORAGE, STORAGEURL } from "../../constants/StorageConst";
import Thumbnail from "../../components/getMaterialThumbnail/Thumbnail";

const MarketingMaterial = () => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const jsonFileTestiRef = ref(
          storage,
          STORAGEURL +
            "/" +
            STORAGE.MATERIALS +
            "/" +
            FILENAME.SUPPORTINGMATERIALS
        );
        const urlMaterial = await getDownloadURL(jsonFileTestiRef);
        const responseMaterial = await fetch(urlMaterial);
        const dataMaterial = await responseMaterial.json();
        setFiles(dataMaterial.files);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching files:", error.message);
        setLoading(false);
      }
    };
    fetchFiles();
  }, []);

  const handleClick = async (file) => {
    const urlRef = ref(
      storage,
      STORAGEURL + "/" + STORAGE.MATERIALS + "/" + file
    );
    const url = await getDownloadURL(urlRef);
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody commonCard">
            {loading && <Loader />}
            <div className="row">
              <div className="col-12">
                <div className="row align-items-center">
                  <div className="col">
                    <h4 className="SectionHeadingMain fw-bold">
                      Supporting Materials
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row py-3">
                {files.length > 0 &&
                  files.map((file, index) => (
                    <div className="col-4 mb-3" key={index}>
                      <div className="card p-3 shadow h-100">
                        <div className="card-body pt-3">
                          <div className="row">
                            <div className="col-8">
                              <div className="cardIcon">
                                <svg
                                  stroke="currentColor"
                                  fill="currentColor"
                                  strokeWidth="0"
                                  viewBox="0 0 512 512"
                                  className="w-20 h-15"
                                  style={{ height: "60px" }}
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M64 464H96v48H64c-35.3 0-64-28.7-64-64V64C0 28.7 28.7 0 64 0H229.5c17 0 33.3 6.7 45.3 18.7l90.5 90.5c12 12 18.7 28.3 18.7 45.3V288H336V160H256c-17.7 0-32-14.3-32-32V48H64c-8.8 0-16 7.2-16 16V448c0 8.8 7.2 16 16 16zM176 352h32c30.9 0 56 25.1 56 56s-25.1 56-56 56H192v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V448 368c0-8.8 7.2-16 16-16zm32 80c13.3 0 24-10.7 24-24s-10.7-24-24-24H192v48h16zm96-80h32c26.5 0 48 21.5 48 48v64c0 26.5-21.5 48-48 48H304c-8.8 0-16-7.2-16-16V368c0-8.8 7.2-16 16-16zm32 128c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H320v96h16zm80-112c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H448v48c0 8.8-7.2 16-16 16s-16-7.2-16-16V432 368z"></path>
                                </svg>
                              </div>
                              <p
                                className="card-text mb-0 pt-3"
                                style={{ textTransform: "uppercase" }}
                              >
                                {file.caption}
                              </p>
                              <button
                                className="editBtn btn btn-outline-dark"
                                onClick={() => handleClick(file.filename)}
                              >
                                <div className="editPencilImg d-flex align-items-center gap-1">
                                  <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    strokeWidth="0"
                                    viewBox="0 0 24 24"
                                    style={{ width: "14px", height: "14px" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path fill="none" d="M0 0h24v24H0z"></path>
                                    <path d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14c1.1 0 2-.9 2-2V5a2 2 0 00-2-2zm0 16H5V7h14v12zm-5.5-6c0 .83-.67 1.5-1.5 1.5s-1.5-.67-1.5-1.5.67-1.5 1.5-1.5 1.5.67 1.5 1.5zM12 9c-2.73 0-5.06 1.66-6 4 .94 2.34 3.27 4 6 4s5.06-1.66 6-4c-.94-2.34-3.27-4-6-4zm0 6.5a2.5 2.5 0 010-5 2.5 2.5 0 010 5z"></path>
                                  </svg>
                                  Preview
                                </div>
                              </button>
                            </div>
                            <div className="col">
                              <Thumbnail image={file.thumbnail} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MarketingMaterial;
