import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import FooterCTA from "../../../components/frontendComponents/footer/FooterCTA";
import WomenImage from "../../../assets/common/img/women.png";
import CircleImage from "../../../assets/common/img/circles.png";
import diagramImage from "../../../assets/common/img/diagram.png";
import icon1 from "../../../assets/common/img/about-1.png";
import icon2 from "../../../assets/common/img/about-2.png";
import icon3 from "../../../assets/common/img/about-3.png";

const About = () => {
  const scrollContainer = useRef(null);

  // Schema markup for medical treatment
  const treatmentSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalTherapy",
    name: "Neuromonics Tinnitus Treatment",
    medicalSpecialty: "Audiology",
    relevantSpecialty: "Tinnitus Treatment",
    study: {
      "@type": "MedicalStudy",
      studySubject: {
        "@type": "MedicalEntity",
        name: "Tinnitus Relief Clinical Trials",
      },
      healthCondition: "Tinnitus",
      outcome:
        "83% success rate with 40% or greater reduction in tinnitus severity",
    },
    recognizingAuthority: "FDA",
    status: "FDA-cleared",
    description:
      "Non-invasive, clinically proven tinnitus treatment using personalized acoustic therapy",
    medicineSystem: "Modern",
    drug: {
      "@type": "Drug",
      name: "Non-pharmaceutical treatment",
    },
  };

  // Reusable arrow components
  // const Arrow = () => (
  //   <svg
  //     width="80"
  //     height="40"
  //     viewBox="0 0 80 40"
  //     className="mx-2 hidden md:block"
  //     aria-hidden="true"
  //   >
  //     <path
  //       d="M0 20H70L55 5L70 20L55 35"
  //       stroke="#1F2937"
  //       strokeWidth="3"
  //       fill="none"
  //       className="animate-draw"
  //     />
  //   </svg>
  // );

  // const UpArrow = () => (
  //   <svg
  //     width="40"
  //     height="60"
  //     viewBox="0 0 40 60"
  //     className="mx-auto my-2"
  //     aria-hidden="true"
  //   >
  //     <path
  //       d="M20 0L35 15L20 0L5 15M20 0V50"
  //       stroke="#1F2937"
  //       strokeWidth="3"
  //       fill="none"
  //       className="animate-draw-vertical"
  //     />
  //   </svg>
  // );

  // Hero Section Component
  const HeroSection = () => (
    <section
      className="pt-10 pb-16 px-10 md:px-16"
      aria-labelledby="hero-heading"
    >
      <div className="container mx-auto">
        <h1
          id="hero-heading"
          className="text-primary text-6xl md:text-7xl font-thin leading-tight md:w-2/3 w-full mb-12"
        >
          Leader in clinically proven device-free Tinnitus Care
        </h1>
        <div className="grid md:grid-cols-2 gap-24 items-start mt-20">
          <div className="space-y-10">
            <div className="space-y-12 text-primary text-2xl mt-20">
              <p>
                The Neuromonics Tinnitus Treatment is a clinically proven,
                patented, FDA-cleared therapy designed to reduce tinnitus
                awareness and disturbance levels.
              </p>
              <p>
                The Neuromonics Tinnitus Treatment (NTT) stems from research and
                PhD by Dr Paul Davis at the University of Western Australia.
              </p>
            </div>
          </div>
          <div className="relative">
            <img
              src={WomenImage}
              alt="Patient experiencing relief with Neuromonics tinnitus treatment app"
              className="rounded-2xl shadow-lg w-full"
              width="600"
              height="400"
              loading="eager"
            />
          </div>
        </div>
      </div>
    </section>
  );

  // Music Meets Science Section Component
  const ScienceSection = () => (
    <section
      className="bg-white py-16 px-10 md:px-16"
      aria-labelledby="science-heading"
    >
      <div className="max-w-7xl mx-auto">
        <h2
          id="science-heading"
          className="text-center text-primary text-[46px] font-roboto leading-[65px] mb-12"
        >
          Music Meets Science
        </h2>
        <div className="grid md:grid-cols-3 gap-20">
          {/* Non-Invasive Treatment */}
          <article className="text-left space-y-4">
            <div className="flex justify-start">
              <img src={icon1} alt="Non-invasive treatment" />
            </div>
            <h3 className="text-[31px] font-roboto leading-[40px] text-primary">
              Non Invasive
            </h3>
            <p className="text-primary text-[20px] font-roboto leading-[26px]">
              Based on your assessment through the Neuromonics app, you will be
              provided with and listen to individually-tailored,
              spectrally-modified relaxation music to provide relief and reduce
              tinnitus perception. The listening schedule is flexible and
              designed to fit into your daily routine, usually requiring 2+
              hours of passive listening each day while you engage in other
              routine daily activities, eg. walking the dog, reading a
              newspaper, engaging in your favourite hobby, etc.
            </p>
          </article>

          {/* Clinically Proven */}
          <article className="text-left space-y-4">
            <div className="flex justify-start">
              <img src={icon2} alt="Clinically proven" />
            </div>
            <h3 className="text-[31px] font-roboto leading-[40px] text-primary">
              Clinically Proven
            </h3>
            <p className="text-primary text-[20px] font-roboto leading-[26px]">
              Neuromonics Tinnitus Treatment has undergone extensive clinical
              trials across Australia and the United States involving over 1,000
              trial subjects and shown to be 83% effective in reducing the
              severity of subjective tinnitus by 40% or more.
            </p>
          </article>

          {/* One-on-One Support */}
          <article className="text-left space-y-4">
            <div className="flex justify-start">
              <img src={icon3} alt="One-on-one support" />
            </div>
            <h3 className="text-[31px] font-roboto leading-[40px] text-primary">
              One on One Support
            </h3>
            <p className="text-primary text-[20px] font-roboto leading-[26px]">
              Throughout the treatment, you'll have regular one-on-one check-ins
              with a Neuromonics-trained clinician. They will support you
              through the six-month treatment program, and monitor your
              progress, provide guidance and assistance, and make adjustments to
              the treatment as needed.
            </p>
          </article>
        </div>
      </div>
    </section>
  );

  // Treatment Components Section
  const TreatmentComponentsSection = () => (
    <section
      className="bg-white mx-auto max-w-900"
      aria-labelledby="components-heading"
    >
      <div className="max-w-7xl mx-auto px-4 pt-10">
        <h2
          id="components-heading"
          className="text-[46px] text-center text-primary mb-12 font-[500] max-w-5xl mx-auto font-roboto leading-[65px]"
        >
          The Neuromonics Tinnitus Treatment is designed to treat all 3
          components of tinnitus:
        </h2>
        <div className="flex justify-center">
          <img
            className="md:w-5/12 w-8/12 pt-6"
            src={CircleImage}
            alt="Neuromonics tinnitus treatment components: Auditory Perception, Attention & Awareness, Emotional Response"
            width="800"
            height="800"
            loading="lazy"
          />
        </div>
      </div>
    </section>
  );

  // Mechanism of Action Section
  const MechanismSection = () => (
    <section
      className="py-16 px-4 md:px-6 bg-white"
      aria-labelledby="mechanism-heading"
    >
      <div className="max-w-3xl mx-auto pt-10">
        <h2
          id="mechanism-heading"
          className="text-6xl text-center text-[#1f2937] mb-12 font-bold"
        >
          Neuromonics:
          <br />
          Mechanism of Action
        </h2>

        <img
          src={diagramImage}
          alt="Neuromonics tinnitus treatment mechanism of action"
        />
      </div>
    </section>
  );

  return (
    <>
      <Helmet>
        <title>
          FDA-Cleared Tinnitus Treatment | Device-Free Solution by Neuromonics
        </title>
        <meta
          name="description"
          content="Discover Neuromonics' clinically proven, FDA-cleared tinnitus treatment. Our device-free solution offers 83% success rate through personalized acoustic therapy and expert support."
        />
        <meta
          name="keywords"
          content="tinnitus treatment, FDA-cleared tinnitus therapy, device-free tinnitus solution, clinically proven tinnitus relief, Neuromonics treatment, acoustic therapy"
        />
        <script type="application/ld+json">
          {JSON.stringify(treatmentSchema)}
        </script>
        <link rel="canonical" href="https://neuromonics.com/about" />
        <meta
          property="og:title"
          content="FDA-Cleared Tinnitus Treatment | Neuromonics"
        />
        <meta
          property="og:description"
          content="Clinically proven, device-free tinnitus treatment with 83% success rate."
        />
        <meta property="og:image" content={WomenImage} />
        <meta property="og:url" content="https://neuromonics.com/about" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <div
        className="frontendOuter paddingTopHeader aboutPadding"
        ref={scrollContainer}
      >
        <Header scrollContainer={scrollContainer} secondHeader={true} />
        <main>
          <HeroSection />
          <ScienceSection />
          <TreatmentComponentsSection />
          <MechanismSection />
          <FooterCTA />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default About;
