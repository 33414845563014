import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/frontendComponents/header/Header";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/free-mode";
import Footer from "../../../components/frontendComponents/footer/Footer";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import womenImage from "../../../assets/common/img/women.png";
import listenImage from "../../../assets/common/img/listen.png";
import workoutImage from "../../../assets/common/img/workout.png";
import FooterCTA from "../../../components/frontendComponents/footer/FooterCTA";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";

const Tinnitus = () => {
  const scrollContainer = useRef(null);
  const [, setJsonDataFirst] = useState(null);
  const [, setJsonDataSecond] = useState(null);
  const [, setIsLoading] = useState(true);

  // Schema markup for tinnitus information
  const tinnitusSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalCondition",
    name: "Tinnitus",
    description:
      "A common auditory condition characterized by the perception of sound when no external sound is present, often described as ringing, buzzing, hissing, or humming in the ears.",
    signOrSymptom: [
      "Ringing in ears",
      "Buzzing sounds",
      "Difficulty concentrating",
      "Sleep disturbances",
    ],
    epidemiology: {
      "@type": "MedicalEntity",
      description:
        "Affects up to 750 million people worldwide, with 50 million cases in the United States",
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const url = await getDownloadURL(jsonFileRef);
        const response = await fetch(url);
        const data = await response.json();
        const jsonDataLength = data.length;
        if (jsonDataLength > 5) {
          if (jsonDataLength % 2 === 0) {
            const halfLength = jsonDataLength / 2;
            const firstHalfData = data.slice(0, halfLength);
            const secondHalfData = data.slice(halfLength);
            setJsonDataFirst(firstHalfData);
            setJsonDataSecond(secondHalfData);
          } else {
            const greaterData = data.slice(0, (jsonDataLength - 1) / 2 + 1);
            const lesserData = data.slice((jsonDataLength - 1) / 2 + 1);
            setJsonDataFirst(greaterData);
            setJsonDataSecond(lesserData);
          }
        } else {
          setJsonDataFirst(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <Helmet>
        <title>What is Tinnitus? | Understanding Symptoms and Treatment</title>
        <meta
          name="description"
          content="Learn about tinnitus, a common condition affecting millions worldwide. Understand its symptoms, causes, prevalence, and impact on daily life. Find relief with evidence-based treatments."
        />
        <meta
          name="keywords"
          content="tinnitus, tinnitus symptoms, ringing in ears, tinnitus treatment, hearing condition, tinnitus relief"
        />
        <link rel="canonical" href="https://neuromonics.com/what-is-tinnitus" />
        <script type="application/ld+json">
          {JSON.stringify(tinnitusSchema)}
        </script>
      </Helmet>

      <div
        className="frontendOuter paddingTopHeader aboutPadding"
        ref={scrollContainer}
      >
        <Header scrollContainer={scrollContainer} secondHeader={true} />

        <main className="mx-auto pt-24 pb-16">
          {/* Hero Section */}
          <section
            className="container mx-auto max-w-7xl grid md:grid-cols-2 items-center mb-20 px-6 md:px-8"
            aria-labelledby="hero-heading"
          >
            <div className="space-y-6">
              <h1
                id="hero-heading"
                className="text-primary text-[77px] font-light leading-[100px] text-start font-roboto"
              >
                What is Tinnitus?
              </h1>
              <div className="space-y-8 text-[#142f68] text-2xl font-roboto leading-[31px] mb-12 pr-8">
                <p>
                  Tinnitus is a common auditory condition experienced by
                  millions worldwide, characterized by the perception of sound
                  when there is no external sound present. Often described as a
                  ringing, buzzing, hissing, or humming in the ears.
                </p>
                <p>
                  Tinnitus can vary in pitch, occur in one or both ears, and may
                  be constant or intermittent. While it affects individuals
                  differently, for many, tinnitus can significantly impact daily
                  life, leading to discomfort, difficulty concentrating, and
                  even sleep disturbances.
                </p>
                <p>
                  Tinnitus is not a disease itself, but a symptom arising from a
                  range of underlying causes, including age-related hearing
                  loss, exposure to loud noises, or certain medical conditions.
                  These causes might include exposure to loud noises, certain
                  medications, ear or sinus infections, age-related hearing
                  loss, various other medical conditions, and/or hearing-related
                  dysfunction.
                </p>
              </div>
            </div>
            <div className="relative mt-8 pl-8">
              <img
                src={womenImage}
                alt="Woman experiencing tinnitus symptoms"
                className="rounded-2xl w-full"
                width="600"
                height="600"
                loading="eager"
              />
            </div>
          </section>

          {/* Prevalence & Impact Section */}
          <section
            className="bg-white py-16 w-full"
            aria-labelledby="prevalence-heading"
          >
            <div className="max-w-7xl mx-auto px-4 md:px-6">
              {/* Prevalence Section */}
              <div className="grid md:grid-cols-2 gap-16 items-center mb-20">
                <div className="space-y-6">
                  <h2
                    id="prevalence-heading"
                    className="text-primary text-2xl md:text-3xl font-medium"
                  >
                    Prevalence of Tinnitus
                  </h2>
                  <div className="space-y-4 text-primary text-lg">
                    <p>
                      Up to 750 million people worldwide suffer from tinnitus In
                      the United States alone, the American Tinnitus Association
                      (ATA) estimates that 50 million people have tinnitus,
                      while separate studies indicate 3-7 million Americans
                      struggle with severe tinnitus
                    </p>
                    <p>
                      There are four different classes of tinnitus: subjective,
                      objective, somatic and neurological, with subjective
                      tinnitus representing as much as 99% of all tinnitus
                      cases.
                    </p>
                  </div>
                  <Link
                    to={FRONTEND_ROUTES.ABOUT}
                    className="inline-flex items-center text-primary font-semibold hover:text-[#2A6A94] transition-colors group mt-4"
                  >
                    Discover Relief
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
                <div className="relative flex justify-center">
                  <img
                    src={listenImage}
                    alt="Understanding tinnitus prevalence and statistics"
                    className="rounded-2xl w-4/5 h-auto object-cover"
                    width="500"
                    height="400"
                    loading="lazy"
                  />
                </div>
              </div>

              {/* Impact Section */}
              <div className="grid md:grid-cols-2 gap-16 items-center">
                <div className="relative flex justify-center">
                  <img
                    src={workoutImage}
                    alt="Impact of tinnitus on daily activities"
                    className="rounded-2xl shadow-lg w-4/5 h-auto object-cover"
                    width="500"
                    height="400"
                    loading="lazy"
                  />
                </div>
                <div className="space-y-6">
                  <h2 className="text-primary text-2xl md:text-3xl font-medium">
                    Impact of Tinnitus
                  </h2>
                  <div className="space-y-4 text-primary text-lg">
                    <p>
                      While tinnitus is typically a hearing condition, it can
                      have a significant impact on a person's quality of life,
                      leading to frustration, sleep disturbances, difficulty
                      concentrating, anxiety and even more severe conditions
                      such as depression or worse in more severe cases. It is
                      important to understand that you are not alone in this
                      experience, and there are many avenues for support and
                      management.
                    </p>
                  </div>
                  <Link
                    to={FRONTEND_ROUTES.ABOUT}
                    className="inline-flex items-center text-primary font-semibold hover:text-[#2A6A94] transition-colors group mt-4"
                  >
                    Discover Relief
                    <ArrowRight className="ml-2 h-5 w-5 group-hover:translate-x-1 transition-transform" />
                  </Link>
                </div>
              </div>
            </div>
          </section>
        </main>

        <FooterCTA />
        <Footer />
      </div>
    </>
  );
};

export default Tinnitus;
