import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight, Calendar, Clock } from "lucide-react";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";

const AppointmentPicker = () => {
  const scrollContainer = useRef(null);
  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = useState("17");
  const [selectedTime, setSelectedTime] = useState(null);
  const [currentMonth] = useState("August 2023");

  // Array of time slots
  const timeSlots = [
    "8:30 AM",
    "8:45 AM",
    "9:00 AM",
    "9:15 AM",
    "9:30 AM",
    "9:45 AM",
    "10:00 AM",
    "10:15 AM",
    "10:30 AM",
    "10:45 AM",
    "11:00 AM",
    "11:15 AM",
    "11:30 AM",
    "11:45 AM",
    "1:00 PM",
  ];

  // Calendar data
  const daysOfWeek = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
  const dates = [
    ["31", "1", "2", "3", "4", "5", "6"],
    ["7", "8", "9", "10", "11", "12", "13"],
    ["14", "15", "16", "17", "18", "19", "20"],
    ["21", "22", "23", "24", "25", "26", "27"],
    ["28", "29", "30", "31", "1", "2", "3"],
  ];

  const handleConfirm = (e) => {
    e.preventDefault();
    navigate(FRONTEND_ROUTES.APPOINTMENT_CONFIRM);
  };

  return (
    <div
      className="min-h-screen bg-primary text-white py-6"
      ref={scrollContainer}
    >
      <Header scrollContainer={scrollContainer} secondHeader={true} />

      <div className="max-w-2xl mx-auto my-20">
        {/* Title */}
        <h2 className="text-center mb-8">
          Pick date, time and appointment type
        </h2>

        {/* Time Range Info */}
        <div className="flex justify-center items-center gap-4 mb-8">
          <div className="flex items-center gap-2">
            <Clock className="w-5 h-5" />
            Mon-Fri, 8:30-11:00 AM
          </div>
          <div className="flex items-center gap-2">
            <Calendar className="w-5 h-5" />
          </div>
        </div>

        {/* Calendar */}
        <div className="bg-white rounded-3xl p-6 mb-8">
          <div className="flex justify-between items-center mb-6">
            <button className="text-gray-400">
              <ChevronLeft className="w-5 h-5" />
            </button>
            <span className="text-gray-900 font-medium">{currentMonth}</span>
            <button className="text-gray-400">
              <ChevronRight className="w-5 h-5" />
            </button>
          </div>

          {/* Calendar Grid */}
          <div className="grid grid-cols-7 gap-2 text-center">
            {/* Days of Week */}
            {daysOfWeek.map((day) => (
              <div key={day} className="text-gray-400 text-sm font-medium py-2">
                {day}
              </div>
            ))}

            {/* Dates */}
            {dates.map((week, weekIndex) => (
              <React.Fragment key={weekIndex}>
                {week.map((date, dateIndex) => (
                  <button
                    key={`${weekIndex}-${dateIndex}`}
                    onClick={() => setSelectedDate(date)}
                    className={`py-2 text-sm rounded-full transition-colors
                      ${
                        selectedDate === date
                          ? "bg-[#1E2C60] text-white"
                          : "text-gray-600 hover:bg-gray-100"
                      }
                      ${
                        date === "31" || parseInt(date) > 25
                          ? "text-gray-300"
                          : ""
                      }
                    `}
                  >
                    {date}
                  </button>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>

        {/* Time Picker */}
        <div className="mb-8">
          <h3 className="text-center text-[#65CCCC] mb-6">
            Pick an available time
          </h3>
          <div className="grid grid-cols-5 gap-3">
            {timeSlots.map((time) => (
              <button
                key={time}
                onClick={() => setSelectedTime(time)}
                className={`relative py-2 px-4 rounded-xl text-sm transition-colors border-2
                  ${
                    selectedTime === time
                      ? "border-[#012f33] bg-white text-gray-900 border-4"
                      : "border-transparent bg-white text-gray-900 hover:bg-gray-100"
                  }
                  ${
                    ["11:30 AM", "11:45 AM"].includes(time)
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }
                `}
                disabled={["11:30 AM", "11:45 AM"].includes(time)}
              >
                {selectedTime === time && (
                  <div className="absolute -top-1 -right-1 w-5 h-5 bg-[#27616e] rounded-full flex items-center justify-center transform scale-1 animate-scale-in">
                    <svg
                      className="w-3 h-3 text-white"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </div>
                )}
                {time}
              </button>
            ))}
          </div>
        </div>

        {/* Confirm Button */}
        <button
          onClick={handleConfirm}
          className={`w-full py-4 rounded-md text-center transition-colors
            ${
              selectedDate && selectedTime
                ? "bg-white text-gray-900 hover:bg-gray-100"
                : "bg-gray-300 text-gray-500 cursor-not-allowed"
            }
          `}
          disabled={!selectedDate || !selectedTime}
        >
          Confirm appointment
        </button>
      </div>

      <Footer />
    </div>
  );
};

export default AppointmentPicker;
