import React, { useState } from "react";
import tinnImg from "../../assets/admin/img/signFinal.jpg";
import { useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import { Link, useNavigate } from "react-router-dom";
import { auth, db } from "../../common/firebase";
import { ADMIN_ROUTES, FRONTEND_ROUTES } from "../../constants/RouteConst";
import Alert from "../../components/alert/Alert";
import { getData } from "../../common/fetch";
import { collection, doc, getDoc } from "firebase/firestore";
import { ROLES } from "../../constants/RoleConst";
import { useAuth } from "../../context/AuthContext";
import closeEye from "../../assets/admin/img/closeeye.svg";
import eye from "../../assets/admin/img/eye.svg";
import { createEvent } from "../../common/helper";
import { EventType } from "../../constants/EventConst";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
// import Loader from "../extra/Loader";

const Login = () => {
  const navigate = useNavigate();
  const [signInWithEmailAndPassword] = useSignInWithEmailAndPassword(auth);
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const { setAuthChecked } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const password = e.target.password.value;
    try {
      setSuccessMsg("");
      setErrorMsg("");
      setIsLoading(true);
      const docData = await getData(email);
      console.log(docData);
      const usersCollection = collection(db, DATABASECOLLECTION.USERPROFILES);
      console.log("userCollection ===> ", usersCollection);
      const userDocRef = doc(usersCollection, docData.uid);
      console.log("userDocRef ===> ", userDocRef);
      const userDocSnap = await getDoc(userDocRef);
      console.log("userDocSnap ===> ", userDocSnap);
      const userStatus = userDocSnap.data();
      const stateRef = collection(userDocRef, DATABASECOLLECTION.STATE);
      const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
      const currentDocSnap = await getDoc(currentDocRef);
      const userDetails = currentDocSnap.data();
      const data = {
        userId: docData.uid,
        type: EventType.LOGIN,
        details: {
          succeeded: false,
        },
      };
      // if (!userDetails.isEmailVerified && docData.customClaims.role === ROLES.CLINICIAN) {
      //   setErrorMsg("Your email has not been verified. Please verify your email to continue!");
      //   return false;
      // }
      if (userDetails && !userDetails.onboardComplete) {
        setIsLoading(false);
        data.details.succeeded = false;
        setErrorMsg(
          "Your account has not been approved yet. Please contact at email antonia@neuromonics.com"
        );
        return false;
      }
      console.log(userStatus);
      if (!userStatus.isActive) {
        setIsLoading(false);
        data.details.succeeded = false;
        setErrorMsg(
          "Your account is deactivated. Please contact at email antonia@neuromonics.com"
        );
        return false;
      }
      const userCredential = await signInWithEmailAndPassword(email, password);
      if (userCredential && userCredential.user) {
        localStorage.setItem("login_email", email);
        localStorage.setItem("role", docData.customClaims.role);
        localStorage.setItem("docid", docData.uid);
        data.details.succeeded = true;
        await createEvent(data);
        setSuccessMsg("Login successfully!");
        setAuthChecked(true);
        setIsLoading(false);
        if (docData.customClaims.role === ROLES.ADMIN) {
          navigate("/manage-users");
        } else {
          navigate("/manage-patients");
        }
      } else {
        console.debug("NO CRED");
        data.details.succeeded = false;
        await createEvent(data);
        setIsLoading(false);
        setErrorMsg("Entered Invalid credential");
      }
    } catch (error) {
      console.debug(error);
      setIsLoading(false);
      setErrorMsg("Entered Invalid credential");
      // setErrorMsg(error.message);
    }
  };

  return (
    <>
      <div className="authpages relative h-screen">
        <div className="signupSection h-full">
          <div className="flex h-full items-center justify-center">
            {/* Left Image Section */}
            <div className="hidden overflow-hidden px-0 lg:block lg:w-1/2">
              <div className="registerImg">
                <div className="relative h-full w-full">
                  <img
                    src={tinnImg}
                    className="h-full w-full object-cover"
                    alt="table accordion"
                  />
                  {/* Home Button */}
                  <div className="absolute bottom-5 left-5 rounded-lg bg-[#34617e]">
                    <div className="flex items-center p-2">
                      <Link
                        to={FRONTEND_ROUTES.HOME_PAGE}
                        className="flex items-center gap-2 text-white no-underline"
                      >
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth="0"
                          viewBox="0 0 512 512"
                          className="h-8 w-8"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path>
                        </svg>
                        <span className="text-sm">Go To Home Page</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Right Form Section */}
            <div className="w-full px-4 sm:w-4/5 lg:w-5/12 xl:w-4/12">
              <div className="inputsignSide py-4 text-center">
                <div className="space-y-4">
                  <div>
                    <h1 className="headingI pb-1 text-3xl">Welcome Back !</h1>
                  </div>
                  <div>
                    <p className="subHeding text-base opacity-60">
                      Sign in to continue to Neuromonics.
                    </p>
                  </div>

                  <form className="MainForm space-y-6" onSubmit={handleSubmit}>
                    {errorMsg && <Alert text={errorMsg} type="error" />}
                    {successMsg && <Alert text={successMsg} type="success" />}
                    {isLoading && (
                      <p className="text-[#34617e] font-semibold italic">
                        Please wait....
                      </p>
                    )}

                    {/* Email Input */}
                    <div className="relative">
                      <input
                        type="email"
                        id="floatingInput"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        placeholder="Email address"
                      />
                      <label
                        htmlFor="floatingInput"
                        className="absolute left-5 top-1/2 text-start -translate-y-1/2 text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Email address
                      </label>
                    </div>

                    {/* Password Input */}
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="floatingPassword"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        placeholder=" "
                      />
                      <label
                        htmlFor="floatingPassword"
                        className="absolute left-5 top-1/2 text-start -translate-y-1/2 text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Password
                      </label>
                      <button
                        type="button"
                        className="absolute right-4 top-1/2 -translate-y-1/2 transform"
                        onClick={handleTogglePassword}
                      >
                        <div className="h-5 w-5">
                          {showPassword ? (
                            <img
                              src={eye}
                              className="h-full w-full"
                              alt="show password"
                            />
                          ) : (
                            <img
                              src={closeEye}
                              className="h-full w-full"
                              alt="hide password"
                            />
                          )}
                        </div>
                      </button>
                    </div>

                    {/* Forgot Password Link */}
                    <div className="text-right">
                      <Link
                        to={ADMIN_ROUTES.FORGET_PASSWORD_URL}
                        className="forgottxt text-theme2 font-semibold no-underline"
                      >
                        Forgotten Password?
                      </Link>
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                      <button
                        className="signinBtn w-full rounded-full bg-theme2 px-6 py-3 text-white transition-all duration-300 hover:opacity-90"
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>

                    {/* Register Link */}
                    <div className="text-center text-sm font-semibold italic">
                      Want to be a Neuromonics clinician?
                      <br />
                      <Link
                        to={ADMIN_ROUTES.SIGNUP_URL}
                        className="forgottxt mt-1 block text-theme2 no-underline"
                      >
                        Register your interest today.
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
