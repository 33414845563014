import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ADMIN_ROUTES, FRONTEND_ROUTES } from "../constants/RouteConst";
import Login from "../pages/login/Login";
import SignUp from "../pages/signup/SignUp";

import Dashboard from "../pages/dashboard/Dashboard";
import Forget from "../pages/forgetpassword/Forget";
import Profile from "../pages/profile/Profile";
import AddPatient from "../pages/managePatients/AddPatient";
import Loader from "../pages/extra/Loader";
import PatientListing from "../pages/managePatients/PatientListing";
import InternalServer from "../pages/extra/InternalServer";
import PageNotFound from "../pages/extra/PageNotFound";
import ClinicianListing from "../pages/manageClinicians/ClinicianListing";
import EditClinician from "../pages/manageClinicians/EditClinician";
import { useAuth } from "../context/AuthContext";
import { ROLES } from "../constants/RoleConst";
import AccessDenied from "../pages/extra/AccessDenied";
import ManageClient from "../pages/managePatients/ManageClient";
import Coupons from "../pages/coupons/Coupons";
import EditCoupon from "../pages/coupons/EditCoupon";
import AddCoupon from "../pages/coupons/AddCoupon.";
import MarketingMaterial from "../pages/marketingMaterial/MarketingMaterial";
import AllPatientListing from "../pages/managePatients/AllPatientListing";
import UserListing from "../pages/manageUsers/UserListing";
import AddAdmin from "../pages/manageUsers/AddAdmin";
import EditUser from "../pages/manageUsers/EditUser.";
import TemplateList from "../pages/manageTemplates/TemplateList";
import EditTemplate from "../pages/manageTemplates/EditTemplate";
import HomePage from "../pages/website/homePage/HomePage";
import Journals from "../pages/website/journals/Journals";
import BlogList from "../pages/website/blogList/BlogList";
import BlogDetail from "../pages/website/blogDetail/BlogDetail";
import BlogListChange from "../pages/website/blogListChange/BlogListChange";
import About from "../pages/website/about/About";
import Tinnitus from "../pages/website/tinnitus/Tinnitus";
import Neuromonics from "../pages/website/neuromonics/Neuromonics";
import FindClinic from "../pages/website/findClinic/FindClinic";
import SingleJournal from "../pages/website/journals/SingleJournal";
import PrivacyPolicy from "../pages/website/privacypolicy/PrivacyPolicy";
import EditRedirect from "../pages/managePayment/EditRedirect";
import EventGraph from "../components/chart/EventGraph";
import PaymentSuccessful from "../pages/paymentSuccessful/PaymentSuccessful";
import PaymentFailed from "../pages/paymentFailed/PaymentFailed";
import StartYourJourney from "../pages/website/startYourJourney/StartYourJourney";
import Membership from "../pages/website/membership/Membership";
import BookDemo from "../pages/website/bookDemo/BookDemo";
import AppointmentConfirm from "../pages/website/appointmentConfirm/AppointmentConfirm";
import Faq from "../pages/website/faq/Faq";

const RoutesComponent = () => {
  const { authChecked } = useAuth();

  return (
    <Routes>
      {/* PUBLIC WEBSITE ROUTES */}
      <Route path={FRONTEND_ROUTES.HOME_PAGE} element={<HomePage />} />
      <Route path={FRONTEND_ROUTES.BLOGS} element={<BlogList />} />
      <Route
        path={FRONTEND_ROUTES.BLOGSLISTCHANGE}
        element={<BlogListChange />}
      />
      <Route
        path={`${FRONTEND_ROUTES.BLOG_DETAILS}/:slug`}
        element={<BlogDetail />}
      />
      <Route path={FRONTEND_ROUTES.JOURNALS} element={<Journals />} />
      <Route path={FRONTEND_ROUTES.ABOUT} element={<About />} />
      <Route path={FRONTEND_ROUTES.TINNITUS} element={<Tinnitus />} />
      <Route path={FRONTEND_ROUTES.NEUROMONICS} element={<Neuromonics />} />
      <Route path={FRONTEND_ROUTES.FINDCLINIC} element={<FindClinic />} />
      <Route
        path={`${FRONTEND_ROUTES.JOURNALS}/:slug`}
        element={<SingleJournal />}
      />
      <Route
        path={FRONTEND_ROUTES.START_YOUR_JOURNEY}
        element={<StartYourJourney />}
      />
      <Route path={FRONTEND_ROUTES.PRIVACYPOLICY} element={<PrivacyPolicy />} />
      <Route path="test" element={<EventGraph />} />
      <Route
        path={FRONTEND_ROUTES.PAYMENT_SUCCESSFUL}
        element={<PaymentSuccessful />}
      />
      <Route
        path={FRONTEND_ROUTES.PAYMENT_FAILED}
        element={<PaymentFailed />}
      />
      <Route path={FRONTEND_ROUTES.MEMBERSHIP} element={<Membership />} />
      <Route path={FRONTEND_ROUTES.BOOK_DEMO} element={<BookDemo />} />
      <Route
        path={FRONTEND_ROUTES.APPOINTMENT_CONFIRM}
        element={<AppointmentConfirm />}
      />
      <Route path={FRONTEND_ROUTES.FAQ} element={<Faq />} />

      {/* PUBLIC WEBSITE ROUTES */}

      {authChecked ? (
        <>
          <Route path={ADMIN_ROUTES.DASHBOARD_URL} element={<Dashboard />} />
          <Route
            path={ADMIN_ROUTES.FORGET_PASSWORD_URL}
            element={<Navigate to={FRONTEND_ROUTES.HOME_PAGE} />}
          />
          <Route path={ADMIN_ROUTES.PROFILE} element={<Profile />} />

          <Route
            path={ADMIN_ROUTES.INTERNAL_SERVER_URL}
            element={<InternalServer />}
          />
          <Route
            path={ADMIN_ROUTES.PAGE_NOT_FOUND_URL}
            element={<PageNotFound />}
          />
          <Route path={ADMIN_ROUTES.LOADER_URL} element={<Loader />} />

          <Route
            path={ADMIN_ROUTES.ACCESS_DENIED_URL}
            element={<AccessDenied />}
          />

          {(localStorage.getItem("role") === ROLES.CLINICIAN ||
            localStorage.getItem("role") === ROLES.CLINICIANINCLINIC ||
            localStorage.getItem("role") === ROLES.CLINICIANINHOUSE) && (
            <>
              <Route
                path={ADMIN_ROUTES.LOGIN_URL}
                element={<Navigate to={ADMIN_ROUTES.MANAGE_PATIENTS_URL} />}
              />
              <Route
                path={ADMIN_ROUTES.SIGNUP_URL}
                element={<Navigate to={ADMIN_ROUTES.MANAGE_PATIENTS_URL} />}
              />
            </>
          )}

          {(localStorage.getItem("role") === ROLES.CLINICIAN ||
            localStorage.getItem("role") === ROLES.CLINICIANINCLINIC ||
            localStorage.getItem("role") === ROLES.CLINICIANINHOUSE ||
            localStorage.getItem("role") === ROLES.ADMIN) &&
          localStorage.getItem("role") != null ? (
            <>
              <Route
                path={ADMIN_ROUTES.MANAGE_PATIENTS_URL}
                element={<PatientListing />}
              />
              <Route path={ADMIN_ROUTES.ADD_PATIENT} element={<AddPatient />} />
              <Route
                path={`${ADMIN_ROUTES.MANAGE_CLIENT_URL}/:id`}
                element={<ManageClient />}
              />
              <Route
                path={`${ADMIN_ROUTES.MARKETING_MATERIALS}`}
                element={<MarketingMaterial />}
              />
            </>
          ) : (
            <Route
              path={"*"}
              element={<Navigate to={FRONTEND_ROUTES.HOME_PAGE} />}
            />
          )}
          {localStorage.getItem("role") === ROLES.ADMIN &&
          localStorage.getItem("role") != null ? (
            <>
              <Route
                path={ADMIN_ROUTES.EDIT_REDIRECT}
                element={<EditRedirect />}
              />
              <Route
                path={ADMIN_ROUTES.MANAGE_ALL_PATIENTS_URL}
                element={<AllPatientListing />}
              />
              <Route
                path={ADMIN_ROUTES.MANAGE_CLINICIAN_URL}
                element={<ClinicianListing />}
              />
              <Route
                path={`${ADMIN_ROUTES.APPROVAL_URL}/:uid`}
                element={<EditClinician />}
              />
              <Route path={ADMIN_ROUTES.COUPONS_URL} element={<Coupons />} />
              <Route
                path={`${ADMIN_ROUTES.EDIT_COUPON}/:uid`}
                element={<EditCoupon />}
              />
              <Route path={ADMIN_ROUTES.ADD_COUPON} element={<AddCoupon />} />
              <Route
                path={ADMIN_ROUTES.MANAGE_USERS_URL}
                element={<UserListing />}
              />
              <Route path={ADMIN_ROUTES.ADD_ADMIN} element={<AddAdmin />} />
              <Route
                path={`${ADMIN_ROUTES.EDIT_USER}/:uid`}
                element={<EditUser />}
              />
              <Route
                path={ADMIN_ROUTES.MANAGE_TEMPLATE}
                element={<TemplateList />}
              />
              <Route
                path={`${ADMIN_ROUTES.EDIT_TEMPLATE}/:uid`}
                element={<EditTemplate />}
              />
              <Route
                path={ADMIN_ROUTES.LOGIN_URL}
                element={<Navigate to={ADMIN_ROUTES.MANAGE_USERS_URL} />}
              />
              <Route
                path={ADMIN_ROUTES.SIGNUP_URL}
                element={<Navigate to={ADMIN_ROUTES.MANAGE_USERS_URL} />}
              />
            </>
          ) : (
            <Route
              path={"*"}
              element={<Navigate to={FRONTEND_ROUTES.HOME_PAGE} />}
            />
          )}

          <Route
            path={"*"}
            element={<Navigate to={FRONTEND_ROUTES.HOME_PAGE} />}
          />
        </>
      ) : (
        <>
          <Route path={ADMIN_ROUTES.LOGIN_URL} element={<Login />} />
          <Route path={ADMIN_ROUTES.SIGNUP_URL} element={<SignUp />} />
          <Route path={ADMIN_ROUTES.FORGET_PASSWORD_URL} element={<Forget />} />
          {/* <Route path={'*'} element={<Navigate to={FRONTEND_ROUTES.BLOGS} />} /> */}
        </>
      )}
      {/* {authChecked && <Route path={ADMIN_ROUTES.LOGIN_URL} element={<Navigate to={FRONTEND_ROUTES.HOME_PAGE} />} />} */}
      {!authChecked && (
        <Route
          path={ADMIN_ROUTES.DASHBOARD_URL}
          element={<Navigate to={ADMIN_ROUTES.LOGIN_URL} />}
        />
      )}
    </Routes>
  );
};

export default RoutesComponent;
