import React from "react";
import { Link } from "react-router-dom";
import logoColoured from "../../../assets/common/img/nuroColur.png";
import { ADMIN_ROUTES, FRONTEND_ROUTES } from "../../../constants/RouteConst";

const HeaderOffcanvas = ({ show, setShow }) => {
  const handleClose = () => setShow(false);

  return (
    <>
      <div
        className={`fixed inset-0 bg-black bg-opacity-50 transition-opacity z-50 ${
          show ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        onClick={handleClose}
      >
        <div
          className={`fixed right-0 top-0 h-full w-80 bg-white shadow-lg transform transition-transform duration-300 ease-in-out ${
            show ? "translate-x-0" : "translate-x-full"
          }`}
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="flex items-center justify-between p-4 border-b">
            <div className="flex-1">
              <img src={logoColoured} alt="Logo" className="h-10" />
            </div>
            <button
              onClick={handleClose}
              className="p-2 hover:bg-gray-100 rounded-full"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>

          {/* Body */}
          <div className="p-4">
            <nav>
              <ul className="space-y-2">
                <li>
                  <Link
                    to={FRONTEND_ROUTES.TINNITUS}
                    className="block px-4 py-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={handleClose}
                  >
                    What is Tinnitus?
                  </Link>
                </li>
                <li>
                  <Link
                    to={FRONTEND_ROUTES.NEUROMONICS}
                    className="block px-4 py-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={handleClose}
                  >
                    Healthcare Professionals
                  </Link>
                </li>
                <li>
                  <Link
                    to={FRONTEND_ROUTES.BLOGS}
                    className="block px-4 py-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={handleClose}
                  >
                    Blog
                  </Link>
                </li>
                <li>
                  <Link
                    to={FRONTEND_ROUTES.JOURNALS}
                    className="block px-4 py-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={handleClose}
                  >
                    Resources
                  </Link>
                </li>
                <li className="mt-4">
                  <Link
                    to={ADMIN_ROUTES.LOGIN_URL}
                    className="block px-4 py-2 text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
                    onClick={handleClose}
                  >
                    Sign In
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOffcanvas;
