import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { Plus } from "lucide-react";
import Header from "../../../components/frontendComponents/header/Header";
import Footer from "../../../components/frontendComponents/footer/Footer";
import girlImage from "../../../assets/common/img/girl.png";

const Faq = () => {
  const scrollContainer = useRef(null);

  // Schema markup for the page
  const pageSchema = {
    "@context": "https://schema.org",
    "@type": "MedicalWebPage",
    name: "Start Your Tinnitus Treatment Journey | Neuromonics",
    description:
      "Begin your personalized tinnitus treatment with Neuromonics. Get access to our FDA-cleared acoustic therapy app and expert clinical support.",
    about: {
      "@type": "MedicalCondition",
      name: "Tinnitus",
    },
  };

  // FAQ Schema
  const faqSchema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is the Neuromonics Tinnitus Treatment?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The Neuromonics Tinnitus Treatment is a multi-faceted approach designed to reduce the perception of tinnitus and its impact on your life. It combines the use of a personalized acoustic neural stimulus delivered through an app, modified Cognitive Behavioral Therapy (CBT) techniques, and guidance from a hearing healthcare professional.",
        },
      },
      {
        "@type": "Question",
        name: "How does the Neuromonics treatment work?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The treatment works by delivering specially processed and customized music through a mobile app. This acoustic therapy helps retrain your brain's neural pathways and reduce the perception of tinnitus over time.",
        },
      },
    ],
  };

  const FaqSection = () => {
    // FAQ Array with updated content
    const faqs = [
      {
        question: "What is the Neuromonics Tinnitus Treatment?",
        answer:
          "The Neuromonics Tinnitus Treatment is a multi-faceted approach designed to reduce the perception of tinnitus and its impact on your life. It combines the use of a personalized acoustic neural stimulus delivered through an app, modified Cognitive Behavioral Therapy (CBT) techniques, and guidance from a hearing healthcare professional.",
      },
      {
        question: "How does the Neuromonics treatment work?",
        answer:
          "The treatment works on three key levels: Auditory - delivering specially processed music that stimulates your auditory system in a calibrated manner, helping you habituate and pay less attention to tinnitus. Neural - retraining your brain's neural pathways to reduce tinnitus perception over time. Emotional - using relaxing music to alleviate stress and anxiety associated with tinnitus, reducing the negative emotional response.",
      },
      {
        question: "What kind of earphones are recommended for the treatment?",
        answer:
          "High-quality earphones are recommended for optimal sound delivery. Specific recommendations include Bose Ultra Open Earbud, Bose Sport wireless earbuds or Bang and Olufsen Beoplay wireless earbuds. Note: Hearing aids with Bluetooth capability are NOT sufficient in their frequency range for the treatment App - please use recommended earphones/earbuds instead.",
      },
      {
        question: "How long does the treatment take?",
        answer:
          "The active treatment period is typically 26 weeks (six months), with treatment sessions of 2-3 hours per day. However, duration can vary based on individual factors such as hearing loss severity, sound tolerance, stress levels, medical history, and treatment compliance. Your audiologist will determine the appropriate treatment length for your specific case.",
      },
      {
        question:
          "I wear hearing aids. Can I still use the Neuromonics treatment?",
        answer:
          "Yes, you can use the Neuromonics treatment while wearing hearing aids. Remove your hearing aids during treatment sessions, which can be scheduled during quieter times when hearing aids aren't needed for communication. Remember that Bluetooth-enabled hearing aids are not suitable for the treatment app due to frequency range limitations.",
      },
      {
        question:
          "What if the treatment is not completely covering my tinnitus?",
        answer:
          "Complete coverage of tinnitus is not necessary for effective treatment. The goal is to provide relief and relaxation. The level of coverage depends on factors like your hearing loss, perceived tinnitus volume, and sound tolerance. Your treatment will be customized to achieve optimal results within these parameters.",
      },
      {
        question: "When will I know if the treatment is working?",
        answer:
          "Progress indicators include reduced tinnitus awareness, improved sleep, better concentration, and decreased anxiety. Your hearing healthcare professional will monitor these changes through regular assessments. Many patients report noticeable improvements within 2-3 months of consistent use.",
      },
      {
        question: "Will I have to use the App forever?",
        answer:
          "After completing the 26-week treatment plan, you'll enter the Maintenance phase. During this period, we recommend using the App for 2-4 hours per week, or as needed. The duration of the Maintenance Phase varies by patient and can be adjusted based on your individual needs and response to treatment.",
      },
      {
        question:
          "Are there any lifestyle changes that can help manage my tinnitus?",
        answer:
          "Yes, several lifestyle modifications can enhance treatment effectiveness: 1) Limit caffeine, alcohol, nicotine, and high-sodium foods that may exacerbate symptoms. 2) Protect your ears from loud noises using appropriate hearing protection. 3) Practice stress management through relaxation techniques like yoga or meditation. 4) Maintain good sleep habits, as fatigue can intensify tinnitus perception.",
      },
      {
        question: "When am I done with treatment?",
        answer:
          "Treatment completion is individualized and depends on your progress and achievement of personal goals. Key indicators include reduced tinnitus awareness, improved quality of life, and better emotional well-being. Your clinician will help determine when you've reached your treatment objectives and guide you through the transition to the maintenance phase.",
      },
    ];

    return (
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200 pb-4">
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => {
                const content = document.getElementById(`faq-${index}`);
                const icon = document.getElementById(`icon-${index}`);
                const isOpen = content?.classList.contains("open");

                // Close all other FAQs
                document.querySelectorAll('[id^="faq-"]').forEach((el, i) => {
                  const otherIcon = document.getElementById(`icon-${i}`);
                  if (i !== index && el && otherIcon) {
                    el.style.maxHeight = "0px";
                    el.classList.remove("open");
                    otherIcon.style.transform = "rotate(0deg)";
                  }
                });

                // Toggle clicked FAQ
                if (isOpen) {
                  content.style.maxHeight = "0px";
                  content.classList.remove("open");
                  icon.style.transform = "rotate(0deg)";
                } else {
                  content.style.maxHeight = "none";
                  content.classList.add("open");
                  icon.style.transform = "rotate(45deg)";
                }
              }}
            >
              <h3 className="text-[#2d4b6d] font-medium pr-8">
                Q. {faq.question}
              </h3>
              <Plus
                id={`icon-${index}`}
                size={20}
                className="text-[#2d4b6d] flex-shrink-0 transition-transform duration-300"
              />
            </div>
            <div
              id={`faq-${index}`}
              className="overflow-hidden transition-all duration-300"
              style={{ maxHeight: "0px" }}
            >
              <p className="text-gray-600 mt-3 leading-relaxed pb-2">
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Helmet>
        <title>Start Your Tinnitus Treatment Journey | Neuromonics App</title>
        <meta
          name="description"
          content="Begin your personalized tinnitus treatment with Neuromonics. Access our FDA-cleared acoustic therapy app, get expert support, and join thousands finding relief from tinnitus."
        />
        <meta
          name="keywords"
          content="tinnitus treatment app, acoustic therapy, tinnitus relief, Neuromonics treatment, tinnitus management"
        />
        <link rel="canonical" href="https://neuromonics.com/start-journey" />
        <script type="application/ld+json">{JSON.stringify(pageSchema)}</script>
        <script type="application/ld+json">{JSON.stringify(faqSchema)}</script>
      </Helmet>

      <div className="bg-white" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} secondHeader={true} />

        <main className="container mx-auto px-4 md:px-8 py-12 mt-16">
          {/* Registration Form Section */}

          {/* FAQ Section */}
          <section
            className="mt-24 max-w-6xl mx-auto"
            aria-labelledby="faq-heading"
          >
            <h2
              id="faq-heading"
              className="text-[49px] text-right text-[#142f68] font-roboto leading-[67px] mb-8"
            >
              Frequently Asked Questions
            </h2>
            <div className="grid lg:grid-cols-2 gap-12 items-start">
              <div>
                <img
                  src={girlImage}
                  alt="Patient experiencing relief with Neuromonics tinnitus treatment"
                  className="rounded-2xl w-full shadow-lg"
                  width="600"
                  height="600"
                  loading="lazy"
                />
              </div>
              <div>
                <FaqSection />
              </div>
            </div>
          </section>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Faq;
