import React from "react";

const SuccessCheckmark = () => (
  <div className="rounded-full bg-white p-4 w-16 h-16 mx-auto mb-8 animate-[appear_0.3s_ease-out]">
    <svg
      viewBox="0 0 24 24"
      className="text-primary animate-[check_0.5s_ease-out_0.3s_forwards]"
    >
      <path d="M0 0h24v24H0z" fill="none"></path>
      <path
        d="M9 16.17 4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"
        fill="#65CCCC"
      ></path>
    </svg>
  </div>
);

const AppointmentConfirm = () => {
  return (
    <div className="min-h-screen bg-primary flex items-center justify-center px-4">
      <div className="text-center animate-[fadeIn_0.5s_ease-out]">
        <SuccessCheckmark />
        <h1 className="text-white text-3xl md:text-4xl font-semibold">
          Your demo is now confirmed!
        </h1>
      </div>
    </div>
  );
};

export default AppointmentConfirm;
