import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ADMIN_ROUTES } from "../constants/RouteConst";

const StyleWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const loadStyles = async () => {
      // Remove any previously loaded stylesheets
      const existingLinks = document.querySelectorAll(
        "link[data-dynamic-styles]"
      );
      existingLinks.forEach((link) => link.remove());

      // Check if current path is an admin route
      const isAdminRoute = Object.values(ADMIN_ROUTES).some((route) =>
        location.pathname.startsWith(route)
      );

      if (isAdminRoute) {
        // Load Bootstrap for admin routes
        const bootstrapLink = document.createElement("link");
        bootstrapLink.href =
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css";
        bootstrapLink.rel = "stylesheet";
        bootstrapLink.setAttribute("data-dynamic-styles", "true");
        document.head.appendChild(bootstrapLink);

        // Load Bootstrap JS
        const bootstrapScript = document.createElement("script");
        bootstrapScript.src =
          "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/js/bootstrap.bundle.min.js";
        bootstrapScript.setAttribute("data-dynamic-script", "true");
        document.body.appendChild(bootstrapScript);
      } else {
        // Load Tailwind for frontend routes
        const tailwindLink = document.createElement("link");
        tailwindLink.href = "/styles/tailwind.css"; // Adjust path to your Tailwind CSS file
        tailwindLink.rel = "stylesheet";
        tailwindLink.setAttribute("data-dynamic-styles", "true");
        document.head.appendChild(tailwindLink);
      }
    };

    loadStyles();

    // Cleanup function
    return () => {
      const existingLinks = document.querySelectorAll("[data-dynamic-styles]");
      const existingScripts = document.querySelectorAll(
        "[data-dynamic-script]"
      );
      existingLinks.forEach((link) => link.remove());
      existingScripts.forEach((script) => script.remove());
    };
  }, [location.pathname]);

  return children;
};

export default StyleWrapper;
