import React, { createContext, useState } from "react";

export const Context = createContext();

const AppContext = (props) => {
  const [sideBarToggle, setsideBarToggle] = useState(false);

  return (
    <Context.Provider
      value={{
        sideBarToggle,
        setsideBarToggle,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default AppContext;
