import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../common/firebase";
import { useEffect, useState } from "react";
import { STORAGE, STORAGEURL } from "../../constants/StorageConst";
import Loader from "../../pages/extra/Loader";

const Thumbnail = ({ image }) => {
  const [storageImage, setStorageImage] = useState();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const jsonFileRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.MATERIALS + "/" + image
      );
      try {
        const url = await getDownloadURL(jsonFileRef);
        setStorageImage(url);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, [image]);
  return (
    <>
      {isLoading && <Loader />}
      {!isLoading && storageImage && (
        <img src={storageImage} alt={image} className="w-100 h-100" />
      )}
    </>
  );
};

export default Thumbnail;
