import React, { useState } from "react";
import tinnImg from "../../assets/admin/img/signFinal.jpg";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { collection, setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../common/firebase";
import { ROLES } from "../../constants/RoleConst";
import Alert from "../../components/alert/Alert";
import { sendEmail, setRoleData } from "../../common/fetch";
import { getTemplateContent } from "../../common/helper";
import { TEMPPLATETYPE } from "../../constants/TemplateTypeConst";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";
import { Link } from "react-router-dom";
import { ADMIN_ROUTES, FRONTEND_ROUTES } from "../../constants/RouteConst";

const SignUp = () => {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [qualifications, setQualifications] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = async () => {
    try {
      setSuccessMsg("");
      setErrorMsg("");
      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const mandatoryFields = [
        firstName,
        lastName,
        email,
        password,
        phone,
        state,
        country,
      ];

      for (const field of mandatoryFields) {
        if (!field) {
          setErrorMsg(
            "Please complete all mandatory fields (indicated by an asterisk)."
          );
          return false;
        }
      }

      if (!isValidEmail(email)) {
        setErrorMsg("Entered invalid email address");
        return false;
      }
      setIsLoading(true);
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userId = userCredential.user.uid;
      const defaultRole = ROLES.CLINICIANPENDING;

      if (userCredential && userCredential.user) {
        try {
          // const user = userCredential.user;
          // const verify = await sendEmailVerification(userCredential.user);
          const clinicianTemplate = await getTemplateContent(
            TEMPPLATETYPE.CLINICIANAPPLICATION
          );
          const templateContent = clinicianTemplate[0].content;
          const updatedEmailContent = templateContent
            .replace(/%%FirstName%%/g, firstName)
            .replace(/%%LastName%%/g, lastName);
          await sendEmail(
            email,
            clinicianTemplate[0].subject,
            updatedEmailContent,
            clinicianTemplate[0].bcc
          );

          await auth.signOut();
          await setRoleData(userId, defaultRole);
          const userProfileDocRef = doc(
            collection(db, DATABASECOLLECTION.USERPROFILES),
            userId
          );
          await setDoc(userProfileDocRef, {
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            emailAddress: email,
            qualifications: qualifications,
            state: state,
            country: country,
            message: message,
            isActive: true,
          });
          const stateRef = collection(
            userProfileDocRef,
            DATABASECOLLECTION.STATE
          );
          const currentDocRef = doc(stateRef, DATABASECOLLECTION.CURRENT);
          const currentDocSnap = await getDoc(currentDocRef);

          if (!currentDocSnap.exists()) {
            await setDoc(currentDocRef, {
              completedHearingTest: false,
              completedTRQ: false,
              connectedClinician: false,
              onboardComplete: false,
            });
          } else {
            await updateDoc(currentDocRef, {
              onboardComplete: false,
            });
          }
          setIsLoading(false);
          setSuccessMsg(
            "User registered successfully! Please check your email for verification."
          );
        } catch (error) {
          if (error.code === "auth/email-already-in-use") {
            setErrorMsg(
              "This email is already in use. Please use a different email."
            );
          } else {
            setErrorMsg(
              "An error occurred during registration. Please try again."
            );
          }
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg(
          "This email is already in use. Please use a different email."
        );
      } else {
        setErrorMsg("An error occurred during registration. Please try again.");
      }
      setIsLoading(false);
    }
  };
  return (
    <div className="authpages h-screen overflow-y-auto">
      <div className="signupSection h-full">
        <div className="flex h-full items-center justify-center">
          {/* Left side image */}
          <div className="hidden h-full overflow-hidden px-0 lg:block lg:w-1/2">
            <div className="registerImg h-full">
              <div className="relative h-full w-full">
                <img
                  src={tinnImg}
                  className="h-full w-full object-cover"
                  alt="table accordion"
                />
                {/* Home Button */}
                <div className="absolute bottom-5 left-5 rounded-lg bg-[#34617e]">
                  <div className="flex items-center p-2">
                    <Link
                      to={FRONTEND_ROUTES.HOME_PAGE}
                      className="flex items-center gap-2 text-white no-underline"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth="0"
                        viewBox="0 0 512 512"
                        className="h-8 w-8"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M216.4 163.7c5.1 5 5.1 13.3.1 18.4L155.8 243h231.3c7.1 0 12.9 5.8 12.9 13s-5.8 13-12.9 13H155.8l60.8 60.9c5 5.1 4.9 13.3-.1 18.4-5.1 5-13.2 5-18.3-.1l-82.4-83c-1.1-1.2-2-2.5-2.7-4.1-.7-1.6-1-3.3-1-5 0-3.4 1.3-6.6 3.7-9.1l82.4-83c4.9-5.2 13.1-5.3 18.2-.3z"></path>
                      </svg>
                      <span className="text-sm">Go To Home Page</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Right form side */}
          <div className="w-full px-4 lg:w-1/2">
            <div className="inputsignSide py-4">
              <div className="mx-auto max-w-3xl">
                <div className="text-center">
                  <h1 className="headingI pb-1 text-3xl font-normal">
                    Sign Up
                  </h1>
                  <p className="subHeding mt-2 text-base text-gray-600 opacity-60">
                    to continue to Neuromonics.
                  </p>
                </div>

                <div className="MainForm mt-8">
                  {errorMsg && <Alert text={errorMsg} type="error" />}
                  {successMsg && <Alert text={successMsg} type="success" />}
                  {isLoading && (
                    <p className="text-[#34617e] font-semibold italic">
                      Please wait....
                    </p>
                  )}

                  <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                    {/* First Name */}
                    <div className="relative">
                      <input
                        type="text"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="firstName"
                        placeholder=" "
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label
                        htmlFor="firstName"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        First Name *
                      </label>
                    </div>

                    {/* Last Name */}
                    <div className="relative">
                      <input
                        type="text"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="lastName"
                        placeholder=" "
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label
                        htmlFor="lastName"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Last Name *
                      </label>
                    </div>

                    {/* Email */}
                    <div className="relative">
                      <input
                        type="email"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="email"
                        placeholder=" "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label
                        htmlFor="email"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Email *
                      </label>
                    </div>

                    {/* Password */}
                    <div className="relative">
                      <input
                        type="password"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="password"
                        placeholder=" "
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <label
                        htmlFor="password"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Password *
                      </label>
                    </div>

                    {/* Phone */}
                    <div className="relative">
                      <input
                        type="tel"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="phone"
                        placeholder=" "
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label
                        htmlFor="phone"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Phone *
                      </label>
                    </div>

                    {/* Qualifications */}
                    <div className="relative">
                      <input
                        type="text"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="qualifications"
                        placeholder=" "
                        value={qualifications}
                        onChange={(e) => setQualifications(e.target.value)}
                      />
                      <label
                        htmlFor="qualifications"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Qualifications
                      </label>
                    </div>

                    {/* State */}
                    <div className="relative">
                      <input
                        type="text"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="state"
                        placeholder=" "
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <label
                        htmlFor="state"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        State *
                      </label>
                    </div>

                    {/* Country */}
                    <div className="relative">
                      <input
                        type="text"
                        className="formcontrolCustom peer h-14 w-full rounded-full border border-gray-300 bg-white px-4 pt-2 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="country"
                        placeholder=" "
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <label
                        htmlFor="country"
                        className="absolute left-5 top-1/2 z-10 origin-[0] -translate-y-1/2 transform text-gray-500 transition-all
                        peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-base
                        peer-focus:top-1 peer-focus:translate-y-0 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-1 peer-[:not(:placeholder-shown)]:translate-y-0 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Country *
                      </label>
                    </div>

                    {/* Message textarea */}
                    <div className="relative col-span-full mt-4">
                      <textarea
                        className="formcontroltxtCustom peer h-24 w-full rounded-xl border border-gray-300 bg-white px-4 pt-6 
                        placeholder-transparent focus:border-theme2 focus:outline-none"
                        id="message"
                        placeholder=" "
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <label
                        htmlFor="message"
                        className="absolute left-4 top-4 z-10 origin-[0] transform text-sm text-gray-500 transition-all
                        peer-placeholder-shown:top-4 peer-placeholder-shown:text-base
                        peer-focus:top-2 peer-focus:text-sm peer-focus:text-theme2
                        peer-[:not(:placeholder-shown)]:top-2 peer-[:not(:placeholder-shown)]:text-sm"
                      >
                        Message (Optional)
                      </label>
                    </div>

                    <div className="col-span-full mt-6 text-center">
                      <button
                        className="signinBtn mx-auto w-1/2 rounded-full bg-theme2 px-6 py-3 text-white transition-all duration-300 hover:opacity-90"
                        onClick={handleRegistration}
                      >
                        Register
                      </button>
                    </div>

                    <div className="col-span-full mt-4 text-center">
                      <span className="text-gray-600">
                        Already have an account?
                      </span>{" "}
                      <Link
                        to={ADMIN_ROUTES.LOGIN_URL}
                        className="forgottxt text-theme2 font-semibold no-underline hover:opacity-80"
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
