import accessDenied from "../../assets/admin/img/access.png";

const AccessDenied = () => {
  return (
    <>
      <div className="adminPAgeFound">
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center">
            <div className="col-12">
              <div className="MainPage text-center pt-5 h-100">
                <div className="number accessDenied pb-1">Access Denied</div>
                {/* <div className="heading pt-2 pb-3 pb-md-4">
                                Access Denied
                            </div> */}
                <div className="row justify-content-center">
                  <div className="col-12 col-sm-10 col-md-9 col-lg-6 col-xl-4">
                    <div className="internalImg accessImg">
                      <img
                        src={accessDenied}
                        className="img-fluid w-100 h-100 object-fitcover"
                        alt="tableAccordion"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AccessDenied;
