import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import logo from "../../../assets/common/img/logo.png";
import "swiper/css/effect-fade";
import "swiper/css";
import "swiper/css/free-mode";
import arrowImage from "../../../assets/common/img/arrow.png";
import heroImage from "../../../assets/common/img/hero.png";
import mobileImage from "../../../assets/common/img/mobile.png";
import appStoreImage from "../../../assets/common/img/app-store.png";
import doctorImage from "../../../assets/common/img/doctor.png";
import familyImage from "../../../assets/common/img/family.png";
import cloudImage from "../../../assets/common/img/cloud.png";
import peopleImage from "../../../assets/common/img/people.png";
import quoteImage from "../../../assets/common/img/quote.png";
import Header from "../../../components/frontendComponents/header/Header";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import FooterCTA from "../../../components/frontendComponents/footer/FooterCTA";
import Footer from "../../../components/frontendComponents/footer/Footer";
import { Link } from "react-router-dom";
import ReactModal from "react-modal";
import { Alert, ModalBody, ModalHeader } from "react-bootstrap";
import { getAuth, signInAnonymously, signOut } from "firebase/auth";
import { checkEmailExists, getTemplateContent } from "../../../common/helper";
import { getData, sendEmail } from "../../../common/fetch";
import { createEvent } from "@testing-library/react";
import { EventType } from "../../../constants/EventConst";
import { TEMPPLATETYPE } from "../../../constants/TemplateTypeConst";
import Loader from "../../extra/Loader";

import { ArrowRight, ChevronLeft, ChevronRight } from "lucide-react";

const HomePage = () => {
  const scrollContainer = useRef(null);
  const [, setJsonDataFirst] = useState(null);
  const [, setJsonDataSecond] = useState(null);
  const [, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoadingModal] = useState(false);

  // To handle Enquire Form
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [message, setMessage] = useState("");

  // const handleClickk = () => {
  //   setIsClicked(!isClicked);
  // };

  // /* Enquiry Modal */
  // const openModal = () => {
  //   setSuccessMsg("");
  //   setErrorMsg("");
  //   setIsModalOpen(true);
  // };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  /* Enquiry Modal */

  // const [autoplayEnabled, setAutoplayEnabled] = useState(true);

  // const handleSliderClick = () => {
  //   setAutoplayEnabled(!autoplayEnabled);
  // };

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const url = await getDownloadURL(jsonFileRef);
        const response = await fetch(url);
        const data = await response.json();
        const jsonDataLength = data.length;
        if (jsonDataLength > 5) {
          if (jsonDataLength % 2 === 0) {
            const halfLength = jsonDataLength / 2;
            const firstHalfData = data.slice(0, halfLength);
            const secondHalfData = data.slice(halfLength);
            setJsonDataFirst(firstHalfData);
            setJsonDataSecond(secondHalfData);
          } else {
            const greaterData = data.slice(0, (jsonDataLength - 1) / 2 + 1);
            const lesserData = data.slice((jsonDataLength - 1) / 2 + 1);
            setJsonDataFirst(greaterData);
            setJsonDataSecond(lesserData);
          }
        } else {
          setJsonDataFirst(data);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, []);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "MedicalApplication",
    name: "Neuromonics Tinnitus Treatment App",
    applicationCategory: "Medical Software",
    operatingSystem: "iOS",
    offers: {
      "@type": "Offer",
      price: "0",
      priceCurrency: "USD",
    },
    description:
      "FDA-cleared tinnitus treatment app with clinically proven success rate. Device-free solution for tinnitus relief.",
    medicalSpecialty: "Audiology",
    award: "FDA Cleared Medical Device",
  };

  // Handle the enquire by Unauthorized or Authorized user from Public web
  const handleEnquire = async () => {
    try {
      setErrorMsg("");
      setSuccessMsg("");
      setIsLoading(true);
      const isValidEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
      };

      const mandatoryFields = [firstName, email, message];

      for (const field of mandatoryFields) {
        if (!field) {
          setIsLoading(false);
          setErrorMsg(
            "Please complete all mandatory fields (indicated by an asterisk)."
          );
          return;
        }
      }

      if (!isValidEmail(email)) {
        setIsLoading(false);
        setErrorMsg(
          "Invalid email format. Please enter a valid email address."
        );
        return;
      }

      const auth = getAuth();
      const checkClientEmailExists = await checkEmailExists(email);
      const userId = checkClientEmailExists
        ? (await getData(email)).uid
        : (await signInAnonymously(auth)).user.uid;

      const data = {
        userId: userId,
        type: EventType.ENQUIRY,
        details: {
          firstName: firstName,
          emailAddress: email,
          state: state,
          country: country,
          message: message,
        },
      };

      await createEvent(data); //create event for enquiry data by unauthorized user from public website

      const adminTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYADMIN
      );
      const templateAdminContent = adminTemplate[0].content;
      const updatedEmailAdminContent = templateAdminContent
        .replace(/%%FirstName%%/g, firstName)
        .replace(/%%Email%%/g, email)
        .replace(/%%State%%/g, state)
        .replace(/%%Country%%/g, country)
        .replace(/%%Message%%/g, message);

      const clientTemplate = await getTemplateContent(
        TEMPPLATETYPE.WEBSITEENQUIRYCLIENT
      );
      const templateClientContent = clientTemplate[0].content;
      const updatedEmailClientContent = templateClientContent.replace(
        /%%FirstName%%/g,
        firstName
      );

      await sendEmail(
        adminTemplate[0].recipient,
        adminTemplate[0].subject,
        updatedEmailAdminContent,
        adminTemplate[0].bcc
      );
      await sendEmail(
        email,
        clientTemplate[0].subject,
        updatedEmailClientContent,
        adminTemplate[0].bcc
      );

      setErrorMsg("");
      // setFirstName('');
      // setEmail('');
      // setState('');
      // setCountry('');
      // setMessage('');
      setIsLoading(false);
      setSuccessMsg("Enquiry sent successfully!");
      await signOut(auth);
    } catch (error) {
      setIsLoading(false);
      if (error.code === "auth/email-already-in-use") {
        setErrorMsg(
          "This email is already in use. Please use a different email."
        );
      } else {
        console.log(error);
        setErrorMsg("An error occurred. Please try again later.");
      }
    }
  };

  const HeroSection = () => (
    <section
      className="pt-20 bg-primary"
      aria-label="Tinnitus Treatment Introduction"
    >
      <div className="py-8 md:py-20 container mx-auto px-10 md:px-16">
        <div className="grid lg:grid-cols-2 gap-8 lg:gap-12 items-center">
          <div className="space-y-6 md:space-y-8">
            <h1 className="text-[#deebec] text-4xl md:text-6xl lg:text-7xl font-light leading-tight">
              The world's most loved, device-free
              <span className="inline-block border-2 border-[#deebec] text-warning rounded-full px-6 md:px-12 py-2 md:py-3 mt-4">
                Tinnitus
              </span>
              <div className="flex items-end gap-4 mt-4">
                <img
                  alt="arrow"
                  src={arrowImage}
                  className="h-6 md:h-8 lg:h-10 mb-2"
                />
                <span>solution</span>
              </div>
            </h1>
            <p className="text-white text-base md:text-lg max-w-xl">
              Experience why healthcare providers trust Neuromonics' clinically
              proven tinnitus treatment
            </p>
            <div className="flex flex-col sm:flex-row gap-4">
              <Link
                to="/start-your-journey"
                className="px-6 md:px-8 py-3 bg-secondary text-primary rounded-xl font-semibold hover:bg-gray-200 transition-colors no-underline text-center"
              >
                Start Your Journey
              </Link>
              <Link
                to="/neuromonics"
                className="px-6 md:px-8 py-3 border border-secondary text-white rounded-xl font-semibold hover:bg-gray-200 hover:text-primary transition-colors no-underline text-center"
              >
                For Providers
              </Link>
            </div>
          </div>
          <div className="lg:flex justify-center mt-8 lg:mt-0 w-full">
            <div className="bg-white rounded-xl mx-auto lg:mx-0 max-w-[500px] w-full">
              <img
                src={heroImage}
                alt="Woman experiencing relief with Neuromonics tinnitus treatment app"
                className="rounded-xl w-full h-full object-cover"
                width="500"
                height="500"
                loading="eager"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const FeaturesSection = () => (
    <section
      className="bg-white py-12 md:py-20"
      aria-labelledby="features-heading"
    >
      <div className="container mx-auto px-4 md:px-16">
        <div className="grid md:grid-cols-2 gap-8 md:gap-20 items-center">
          <div className="relative w-full md:max-w-[300px] mx-auto order-2 md:order-1">
            <div className="relative w-full h-[600px] flex justify-center">
              <img
                src={mobileImage}
                alt="Neuromonics tinnitus treatment app interface showing personalized sound therapy controls"
                className="w-full h-full object-contain"
                width="300"
                height="600"
                loading="lazy"
              />
            </div>
          </div>
          <div className="space-y-4 w-full md:w-96 order-1 md:order-2">
            <div className="flex items-center gap-2">
              <h2
                id="features-heading"
                className="text-primary text-4xl md:text-5xl text-center md:text-left"
              >
                Patented &{" "}
                <img
                  src={cloudImage}
                  alt="cloud"
                  className="inline-block w-16 h-12"
                />{" "}
                Clinically Proven
              </h2>
            </div>
            <p className="text-[#313a41] text-base md:text-lg text-center md:text-left">
              We have made our FDA cleared Tinnitus Treatment extremely easy to
              use for both clinicians and patients without the need for a
              specialized external device.
            </p>
            <div className="flex justify-center md:justify-start">
              <Link
                to="/about"
                className="group flex items-center gap-2 text-primary font-semibold"
                aria-label="Learn more about our clinical research and tinnitus relief studies"
              >
                Discover Relief
                <ArrowRight
                  size={20}
                  className="group-hover:translate-x-1 transition-transform"
                  aria-hidden="true"
                />
              </Link>
            </div>
            <div className="flex justify-center md:justify-start">
              <a
                href="https://apps.apple.com/us/app/neuromonics-tinnitus-treatment/id6470243215"
                className="inline-block mt-4 transform hover:scale-105 transition-transform"
                target="_blank"
                rel="noreferrer"
                aria-label="Download Neuromonics Tinnitus Treatment app from the App Store"
              >
                <img
                  src={appStoreImage}
                  alt="Download Neuromonics Tinnitus Treatment on the App Store"
                  className="h-16 md:h-20"
                  width="200"
                  height="80"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const SuccessRateSection = () => (
    <section
      className="bg-white py-12 md:py-20"
      aria-labelledby="success-rate-heading"
    >
      <div className="container mx-auto px-10 md:px-16">
        <div className="grid md:grid-cols-2 gap-8 md:gap-20 items-center">
          <div className="space-y-2">
            <div className="flex flex-col items-start gap-1">
              <span className="font-bold items-center">
                <img
                  src={peopleImage}
                  alt=""
                  className="inline-block w-8 h-12"
                />
                <span className="sr-only">Patient Success Statistics</span>
              </span>
              <h2
                id="success-rate-heading"
                className="text-primary text-4xl md:text-5xl font-semibold"
              >
                83% Success Rate
              </h2>
            </div>
            <p className="text-[#313a41] text-base md:text-lg">
              40% + Reduction in TRQ score achieved across 20 clinical trials in
              the United States and Australia involving 1,000+ clinical trail
              subjects.
            </p>
            <Link
              to="/about"
              className="group inline-flex items-center gap-2 text-primary font-semibold"
              aria-label="View detailed clinical study results for tinnitus treatment"
            >
              Discover more
              <ArrowRight
                size={20}
                className="group-hover:translate-x-1 transition-transform"
                aria-hidden="true"
              />
            </Link>
          </div>
          <div className="relative">
            <div className="aspect-w-4 aspect-h-3">
              <img
                src={doctorImage}
                alt="Healthcare professional discussing Neuromonics tinnitus treatment results with patient"
                className="rounded-xl w-full h-full object-cover"
                width="600"
                height="450"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );

  const TreatmentSteps = () => (
    <section
      className="bg-white py-12 md:py-20"
      aria-labelledby="treatment-steps-heading"
    >
      <div className="container mx-auto px-10 md:px-16">
        <h2
          id="treatment-steps-heading"
          className="text-primary text-2xl md:text-3xl mb-12 md:mb-16"
        >
          At Neuromonics, we provide a comprehensive, step-by-step journey
          towards effective tinnitus management, tailored to your unique needs.
        </h2>

        <div className="max-w-4xl mx-auto px-4 md:px-6">
          <div className="grid gap-8 md:gap-12">
            <article className="flex flex-col md:flex-row items-start gap-8">
              <div
                className="flex-shrink-0 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 rounded-full bg-primary bg-opacity-10"
                aria-hidden="true"
              >
                <span className="text-primary text-2xl md:text-3xl font-bold">
                  1
                </span>
              </div>
              <div className="flex-1 flex flex-col gap-4">
                <h3 className="text-primary text-xl md:text-2xl font-semibold">
                  Individually-tailored, Spectrally Modified Relaxation Music
                </h3>
                <p className="text-primary text-base md:text-lg">
                  Experience customized music from Grammy Award Winning artist,
                  fine-tuned to your hearing profile, designed for 2+ hours of
                  daily listening for maximum therapeutic impact.
                </p>
              </div>
            </article>

            <article className="flex flex-col md:flex-row items-start gap-8">
              <div
                className="flex-shrink-0 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 rounded-full bg-primary bg-opacity-10"
                aria-hidden="true"
              >
                <span className="text-primary text-2xl md:text-3xl font-bold">
                  2
                </span>
              </div>
              <div className="flex-1 flex flex-col gap-4">
                <h3 className="text-primary text-xl md:text-2xl font-semibold">
                  One-on-One Guided Counselling Sessions
                </h3>
                <p className="text-primary text-base md:text-lg">
                  Receive personalized guidance and support for the six month
                  treatment program, enhancing your journey to tinnitus
                  understanding, relief and well-being
                </p>
              </div>
            </article>

            <article className="flex flex-col md:flex-row items-start gap-8">
              <div
                className="flex-shrink-0 flex items-center justify-center w-12 h-12 md:w-16 md:h-16 rounded-full bg-primary bg-opacity-10"
                aria-hidden="true"
              >
                <span className="text-primary text-2xl md:text-3xl font-bold">
                  3
                </span>
              </div>
              <div className="flex-1 flex flex-col gap-4">
                <h3 className="text-primary text-xl md:text-2xl font-semibold">
                  Stress Reduction
                </h3>
                <p className="text-primary text-base md:text-lg">
                  Experience guided breathing and relaxation exercises.
                </p>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );

  const TestimonialSection = () => {
    const [currentTestimonial, setCurrentTestimonial] = useState(0);
    const [isAnimating, setIsAnimating] = useState(false);

    const testimonials = [
      {
        quote:
          "Most of my tinnitus patients come to me in tears. They are desperate. I can help them, though—help them keep their jobs, be good parents, regain their lives—with Neuromonics' FDA-cleared tinnitus treatment app.",
        author: "Dr. Sarah Johnson",
        role: "Senior Audiologist, Tinnitus Treatment Specialist",
        initials: "SJ",
        image: familyImage,
        clinic: "Advanced Hearing Care Center",
        location: "Boston, MA",
      },
      {
        quote:
          "Neuromonics has transformed how we treat tinnitus. The app-based treatment delivers consistent results, and our patients appreciate having a device-free solution that fits seamlessly into their daily lives.",
        author: "Dr. Michael Chen",
        role: "Clinical Audiologist, Tinnitus Research Lead",
        initials: "MC",
        image: familyImage,
        clinic: "Pacific Tinnitus & Hearing Institute",
        location: "San Francisco, CA",
      },
    ];

    const changeTestimonial = (direction) => {
      setIsAnimating(true);
      setTimeout(() => {
        if (direction === "next") {
          setCurrentTestimonial((prev) =>
            prev === testimonials.length - 1 ? 0 : prev + 1
          );
        } else {
          setCurrentTestimonial((prev) =>
            prev === 0 ? testimonials.length - 1 : prev - 1
          );
        }
        setIsAnimating(false);
      }, 300); // Match this with CSS transition duration
    };

    // Auto-slide functionality
    useEffect(() => {
      const timer = setInterval(() => {
        changeTestimonial("next");
      }, 5000); // Change testimonial every 5 seconds

      return () => clearInterval(timer); // Cleanup on unmount
    }, []);

    const nextTestimonial = () => changeTestimonial("next");
    const prevTestimonial = () => changeTestimonial("prev");

    // Schema markup for testimonials
    const testimonialSchema = {
      "@context": "https://schema.org",
      "@type": "Review",
      itemReviewed: {
        "@type": "MedicalApplication",
        name: "Neuromonics Tinnitus Treatment App",
        applicationCategory: "Medical Software",
      },
      author: {
        "@type": "Person",
        name: testimonials[currentTestimonial].author,
        jobTitle: testimonials[currentTestimonial].role,
        worksFor: {
          "@type": "MedicalOrganization",
          name: testimonials[currentTestimonial].clinic,
          address: {
            "@type": "PostalAddress",
            addressLocality: testimonials[currentTestimonial].location,
          },
        },
      },
      reviewRating: {
        "@type": "Rating",
        ratingValue: "5",
        bestRating: "5",
      },
      reviewBody: testimonials[currentTestimonial].quote,
    };

    return (
      <section
        className="bg-white py-12 md:py-20"
        aria-labelledby="testimonials-heading"
      >
        <script type="application/ld+json">
          {JSON.stringify(testimonialSchema)}
        </script>

        <div className="container mx-auto px-10 md:px-16">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-8 md:mb-12">
            <h2
              id="testimonials-heading"
              className="text-primary text-2xl md:text-3xl font-semibold"
            >
              What clients say about Neuromonics
            </h2>
            <div
              className="flex gap-3"
              role="navigation"
              aria-label="Testimonial navigation"
            >
              <button
                onClick={prevTestimonial}
                className="w-10 h-10 rounded-full border-2 border-primary border-solid flex items-center justify-center hover:border-opacity-70 transition-all"
                aria-label="View previous testimonial"
              >
                <ChevronLeft
                  className="w-6 h-6 text-primary"
                  aria-hidden="true"
                />
              </button>
              <button
                onClick={nextTestimonial}
                className="w-10 h-10 rounded-full border-2 border-primary border-solid flex items-center justify-center hover:border-opacity-70 transition-all"
                aria-label="View next testimonial"
              >
                <ChevronRight
                  className="w-6 h-6 text-primary"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>

          <div className="grid md:grid-cols-5 gap-8 md:gap-12 items-center">
            <div className="space-y-6 order-2 md:order-1 md:col-span-3">
              <img src={quoteImage} alt="Quote" className="w-14 -pr-20 -pb-4" />
              <blockquote
                className={`text-primary text-xl md:text-2xl font-semibold italic transform transition-all duration-300 leading-relaxed ${
                  isAnimating
                    ? "opacity-0 translate-x-6"
                    : "opacity-100 translate-x-0"
                }`}
              >
                <p className="leading-loose">
                  {testimonials[currentTestimonial].quote}
                </p>
              </blockquote>
              <footer
                className={`flex items-center gap-4 transform transition-all duration-300 ${
                  isAnimating
                    ? "opacity-0 translate-x-6"
                    : "opacity-100 translate-x-0"
                }`}
              >
                <div
                  className="w-12 h-12 rounded-full bg-primary bg-opacity-10 flex items-center justify-center"
                  aria-hidden="true"
                >
                  <span className="text-primary font-bold">
                    {testimonials[currentTestimonial].initials}
                  </span>
                </div>
                <div>
                  <cite className="text-primary font-semibold not-italic">
                    {testimonials[currentTestimonial].author}
                  </cite>
                  <p className="text-primary text-sm">
                    {testimonials[currentTestimonial].role}
                  </p>
                  <p className="text-primary text-sm">
                    {testimonials[currentTestimonial].clinic},{" "}
                    {testimonials[currentTestimonial].location}
                  </p>
                </div>
              </footer>
            </div>
            <div className="relative order-1 md:order-2 md:col-span-2">
              <div className="aspect-w-4 aspect-h-3">
                <img
                  src={testimonials[currentTestimonial].image}
                  alt={`${testimonials[currentTestimonial].author} from ${testimonials[currentTestimonial].clinic} discussing Neuromonics tinnitus treatment success`}
                  className={`rounded-xl w-full h-full object-cover shadow-lg`}
                  width="600"
                  height="450"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const PartnersSection = () => {
    const partners = [
      {
        id: 1,
        name: "University of Cincinnati",
        image: "/partners/partner-1.png",
      },
      { id: 2, name: "NeuroSensory", image: "/partners/partner-2.png" },
      { id: 3, name: "VA Healthcare", image: "/partners/partner-3.png" },
      {
        id: 4,
        name: "University of Kentucky",
        image: "/partners/partner-4.png",
      },
      {
        id: 5,
        name: "Washington University",
        image: "/partners/partner-5.png",
      },
      {
        id: 6,
        name: "University of Kentucky Healthcare",
        image: "/partners/partner-6.png",
      },
    ];

    // Schema markup for partners/organizations
    const partnerSchema = {
      "@context": "https://schema.org",
      "@type": "MedicalOrganization",
      name: "Neuromonics",
      medicalSpecialty: "Audiology",
      memberOf: partners.map((partner) => ({
        "@type": "MedicalOrganization",
        name: partner.name,
      })),
    };

    return (
      <section
        className="bg-white py-12 md:py-16 overflow-hidden"
        aria-labelledby="partners-heading"
      >
        <script type="application/ld+json">
          {JSON.stringify(partnerSchema)}
        </script>

        <div className="container mx-auto px-6 md:px-8">
          <h2
            id="partners-heading"
            className="text-primary text-2xl md:text-3xl mb-12 text-center font-semibold"
          >
            Trusted by Leading Healthcare Institutions
          </h2>
          <div
            className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4 md:gap-6 items-center justify-items-center"
            role="list"
            aria-label="Healthcare partners"
          >
            {partners.map((partner) => (
              <div
                key={partner.id}
                className="flex items-center justify-center w-full"
                role="listitem"
              >
                <img
                  src={partner.image}
                  alt={`${partner.name} - Trusted Healthcare Partner for Tinnitus Treatment`}
                  className="h-20 w-auto object-contain"
                  loading="lazy"
                  width="200"
                  height="80"
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };

  return (
    <>
      <Helmet>
        <title>FDA-Cleared Tinnitus Treatment App | Neuromonics</title>
        <meta
          name="description"
          content="Experience clinically proven tinnitus relief with Neuromonics' FDA-cleared app. 83% success rate across 20+ clinical trials. Download our device-free solution today."
        />
        <meta
          name="keywords"
          content="tinnitus treatment app, FDA-cleared tinnitus therapy, clinically proven tinnitus relief, Neuromonics app"
        />
        <meta
          property="og:title"
          content="FDA-Cleared Tinnitus Treatment App | Neuromonics"
        />
        <meta
          property="og:description"
          content="Experience clinically proven tinnitus relief with Neuromonics' FDA-cleared app. 83% success rate across 20+ clinical trials."
        />
        <meta property="og:image" content={heroImage} />
        <meta property="og:url" content="https://neuromonics.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="canonical" href="https://neuromonics.com" />
        <script type="application/ld+json">
          {JSON.stringify(structuredData)}
        </script>
      </Helmet>
      <div className="frontendOuter" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} />
        <main>
          <HeroSection />
          <FeaturesSection />
          <SuccessRateSection />
          <TreatmentSteps />
          <TestimonialSection />
          <PartnersSection />
        </main>
        <FooterCTA />
        <Footer />
      </div>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Your Modal"
        className="custom-modal"
        style={{
          content: {
            // width: '50%',
            height: "90%",
            margin: "auto",
            left: "356px",
            marginTop: "20px",

            backgroundColor: "#ffffff",
            border: "1px solid",
            overflow: "auto",
            display: "flex",
            flexDirection: "column",
          },
          overlay: {
            backgroundColor: "rgb(255 255 255 / 88%)",
            zIndex: "99999",
          },
        }}
      >
        <ModalHeader
          style={{
            padding: "10px",
            background: "#517991",
            color: "#fff",
            position: "sticky",
            top: "0",
            zIndex: "100000",
          }}
          className="row gx-0 align-items-center justify-content-center"
        >
          <div className="col-10">
            <div className="enqnumLogo">
              <img src={logo} alt="" />
            </div>
          </div>
          <div className="col-2">
            <Link
              className="text-light d-flex justify-content-end"
              onClick={closeModal}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 24 24"
                style={{ height: "24px", width: "24px" }}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.0007 10.5865L16.9504 5.63672L18.3646 7.05093L13.4149 12.0007L18.3646 16.9504L16.9504 18.3646L12.0007 13.4149L7.05093 18.3646L5.63672 16.9504L10.5865 12.0007L5.63672 7.05093L7.05093 5.63672L12.0007 10.5865Z"></path>
              </svg>
            </Link>
          </div>
        </ModalHeader>
        <ModalBody
          style={{
            overflow: "auto",
            maxHeight: "73%%",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="col-12">
            <div className="enquiretxt mb-2">Enquiry Form</div>
            {/* <p className="enqpara">
                            Please fill out the form below to send your inquiry.
                        </p> */}
          </div>
          <div className="col-12 flex-fill">
            {errorMsg && <Alert text={errorMsg} type="error" />}
            {successMsg && <Alert text={successMsg} type="success" />}
            {isLoadingModal && <Loader isEnquieySpinner={true} />}
            {!isLoadingModal && (
              <div className="MainForm">
                <div className="row">
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="firstName"
                        name="firstName"
                        placeholder=""
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label htmlFor="firstName" className="formControlLable">
                        <div className="labelTxt">Name *</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="email"
                        name="email"
                        placeholder=""
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label htmlFor="email" className="formControlLable">
                        <div className="labelTxt">Email *</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="state"
                        name="state"
                        placeholder=""
                        value={state}
                        onChange={(e) => setState(e.target.value)}
                      />
                      <label htmlFor="state" className="formControlLable">
                        <div className="labelTxt">State</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        className="form-control shadow-none border formcontrolCustom modalRound"
                        id="country"
                        name="country"
                        placeholder=""
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                      <label htmlFor="country" className="formControlLable">
                        <div className="labelTxt">Country</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating mb-3 ">
                      <textarea
                        className="form-control formcontroltxtCustom shadow-none maptextarea"
                        rows="3"
                        placeholder="Leave a comment here"
                        name="message"
                        id="message"
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      ></textarea>
                      <label htmlFor="message">
                        <div className="labelTxt">Message*</div>
                      </label>
                    </div>
                  </div>
                  <div className="col-6 mx-auto">
                    <div className="signInButton mt-3 text-center">
                      <button className="signinBtn" onClick={handleEnquire}>
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
      </ReactModal>
    </>
  );
};

export default HomePage;
