import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { Link, useNavigate, useParams } from "react-router-dom";
import he from "he";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";
import Loader from "../../loader/Loader";
import Footer from "../../../components/frontendComponents/footer/Footer";

const BlogDetail = () => {
  const scrollContainer = useRef(null);
  const slug = useParams();
  const [jsonData, setJsonData] = useState(null);
  const [bodyData, setBodyData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigator = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileBlogRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.BLOGFILENAME
      );
      const jsonFileTestiRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const urlBlog = await getDownloadURL(jsonFileBlogRef);
        const urlTesti = await getDownloadURL(jsonFileTestiRef);
        const responseBlog = await fetch(urlBlog);
        const responseTesti = await fetch(urlTesti);
        const dataBlog = await responseBlog.json();
        const dataTesti = await responseTesti.json();
        const mergedData = [...dataBlog, ...dataTesti];
        const indexOfObject = mergedData.findIndex(
          (item) => item.slug === slug.slug
        );

        if (indexOfObject !== -1) {
          const targetObject = mergedData[indexOfObject];
          setJsonData(targetObject);
          const htmlBody = ref(
            storage,
            STORAGEURL + "/" + STORAGE.BLOGS + "/" + targetObject.body
          );
          const bodyContent = await getDownloadURL(htmlBody);
          const responseBody = await fetch(bodyContent);
          const htmlContent = await responseBody.text();
          const decodedData = he.decode(htmlContent);
          setBodyData(decodedData);
          const tableExists = decodedData.includes("<table");
          const paraExists = decodedData.includes("<p");
          const ulExists = decodedData.includes("<ul");
          const liExists = decodedData.includes("<li");
          const olExists = decodedData.includes("<ol");
          const italicExists = decodedData.includes("<i");
          if (tableExists || paraExists) {
            let modifiedHtml = decodedData;
            if (tableExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<table",
                '<table class="table table-bordered table-light"'
              );
            }
            if (paraExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<p",
                '<p class="blogPara"'
              );
            }
            if (ulExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<ul",
                '<ul class="blogUl"'
              );
            }
            if (liExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<li",
                '<li class="space letter-spacing"'
              );
            }
            if (olExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<ol",
                '<ol class="blogUl"'
              );
            }
            if (italicExists) {
              modifiedHtml = modifiedHtml.replaceAll(
                "<i",
                '<i class="textItalic"'
              );
            }
            setBodyData(modifiedHtml);
          }
        } else {
          navigator("/404");
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <div className="frontendOuter paddingTopHeader" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} secondHeader={true} />
        {isLoading && <Loader />}
        {!isLoading && jsonData && (
          <div className="container-fluid blogDetailFluid py-5">
            <div className="container">
              <div className="row heroBanner justify-content-center mx-0">
                <div className="col-lg-8 col-12">
                  <div className="row">
                    <div className="col-12">
                      <div className="heroHeading heroHeadingBlog text-left">
                        {jsonData.title}{" "}
                        {jsonData?.sub_title ? "- " + jsonData.sub_title : ""}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="blogAuthor">
                        <strong>by: </strong>
                        {jsonData.author ?? jsonData.name}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="date">
                        {jsonData.date ?? "Role: " + jsonData.role}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mx-0">
                <div className="col-lg-8 col-12">
                  {bodyData && (
                    <div
                      className="row"
                      dangerouslySetInnerHTML={{ __html: bodyData }}
                    ></div>
                  )}
                </div>
              </div>
              <div className="row justify-content-between py-5">
                <div className="col-auto">
                  <Link
                    to={FRONTEND_ROUTES.BLOGS}
                    className="blogLink d-flex align-items-center"
                  >
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M11.67 3.87L9.9 2.1 0 12l9.9 9.9 1.77-1.77L3.54 12z"></path>
                    </svg>
                    Back
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
        {/* footer start */}
        <div className="container-fluid sectionOneFluid footerFluid">
          <Footer />
        </div>
        {/* footer end */}
      </div>
    </>
  );
};

export default BlogDetail;
