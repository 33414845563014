import React, { useEffect, useState } from "react";
import AdimnSidebar from "../../components/adminSidebar/AdminSidebar";
import AdminHeader from "../../components/adminHeader/AdminHeader";
import {
  collection,
  doc,
  getDoc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../common/firebase";
import Alert from "../../components/alert/Alert";
import { useParams } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TEMPPLATETYPE } from "../../constants/TemplateTypeConst";
import { DATABASECOLLECTION } from "../../constants/DatabaseConst";

const EditTemplate = () => {
  const uid = useParams();
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [purpose, setPurpose] = useState("");
  const [bcc, setBCC] = useState("");
  const [recipient, setRecipient] = useState("");
  const [type, setType] = useState("");
  const [data, setData] = useState("");

  const handleUpdate = async () => {
    try {
      setSuccessMsg("");
      setErrorMsg("");
      const validationRules = [
        {
          field: subject,
          message: "The subject name field must not be left blank.",
        },
        {
          field: content,
          message: "The content name field must not be left blank.",
        },
      ];
      for (const rule of validationRules) {
        if (!rule.field) {
          setErrorMsg(rule.message);
          return false;
        }
      }
      try {
        const userProfileDocRef = doc(
          collection(db, DATABASECOLLECTION.TEMPLATES),
          uid.uid
        );
        const updateData = {
          subject: subject,
          content: content,
          purpose: purpose,
          bcc: bcc,
          updated_at: serverTimestamp(),
        };

        // Check if recipient is not empty before including it in the update
        if (recipient) {
          updateData.recipient = recipient;
        }

        await updateDoc(userProfileDocRef, updateData);
        // await updateDoc(userProfileDocRef, {
        //     subject: subject,
        //     content: content,
        //     purpose: purpose,
        //     bcc: bcc,
        //     recipient: recipient,
        //     updated_at: serverTimestamp(),
        // });
        setSuccessMsg("Email template updated successfully!");
      } catch (error) {
        console.log(error);
        setErrorMsg("An error occurred during registration. Please try again.");
      }
    } catch (error) {
      setErrorMsg("An error occurred during registration. Please try again.");
    }
  };

  useEffect(() => {
    const getEmailTemplate = async () => {
      const templateCollection = collection(db, DATABASECOLLECTION.TEMPLATES);
      const templateDocRef = doc(templateCollection, uid.uid);
      const templateDocumentSnapshot = await getDoc(templateDocRef);
      if (templateDocumentSnapshot.data()) {
        const templateDetails = templateDocumentSnapshot.data();
        setSubject(templateDetails.subject);
        setContent(templateDetails.content);
        setType(templateDetails.type);
        setPurpose(templateDetails.purpose);
        setBCC(templateDetails.bcc);
        setRecipient(templateDetails.recipient);
        setData(templateDetails);
      }
    };
    getEmailTemplate();
  }, [uid.uid]);

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader />
          <div className="adminBody">
            <div className="registerUser commonCard">
              <div className="row">
                <div className="col-12">
                  <div className="card-header ">
                    <div className="SectionHeadingMain">
                      Update {data && data.type} Template
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="MainForm">
                    {errorMsg && <Alert text={errorMsg} type="error" />}
                    {successMsg && <Alert text={successMsg} type="success" />}
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border"
                            id="subject"
                            name="subject"
                            placeholder=""
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          <label htmlFor="subject" className="formControlLable">
                            <div className="labelTxt">Subject</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <div className="form-floating mb-3">
                          <input
                            type="text"
                            className="form-control shadow-none border"
                            id="subject"
                            name="subject"
                            placeholder=""
                            value={bcc}
                            onChange={(e) => setBCC(e.target.value)}
                          />
                          <label htmlFor="purpose" className="formControlLable">
                            <div className="labelTxt">BCC Email</div>
                          </label>
                        </div>
                      </div>
                      {TEMPPLATETYPE.WEBSITEENQUIRYADMIN === type && (
                        <div className="col-12 col-sm-12">
                          <div className="form-floating mb-3">
                            <input
                              type="text"
                              className="form-control shadow-none border"
                              id="recipient"
                              name="recipient"
                              placeholder=""
                              value={recipient}
                              onChange={(e) => setRecipient(e.target.value)}
                            />
                            <label
                              htmlFor="recipient"
                              className="formControlLable"
                            >
                              <div className="labelTxt">Recipient</div>
                            </label>
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-sm-12">
                        <div className="form-floating mb-3">
                          <textarea
                            type="text"
                            className="form-control shadow-none border"
                            id="purpose"
                            name="purpose"
                            placeholder=""
                            value={purpose}
                            style={{ height: "100px" }}
                            onChange={(e) => setPurpose(e.target.value)}
                          >
                            {purpose}
                          </textarea>
                          <label htmlFor="purpose" className="formControlLable">
                            <div className="labelTxt">Purpose/Description</div>
                          </label>
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <label>
                          <div className="labelTxt">Content</div>
                        </label>
                        <div className="form-floating mb-3 ">
                          <ReactQuill
                            className="shadow-none"
                            value={content}
                            onChange={setContent}
                            placeholder="Enter content here"
                          />
                          {TEMPPLATETYPE.ADDCLIENTC === type && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided variables
                              (specifically valid for this template) <br />
                              <u>%%ClinicianName%% </u>,<u>%%FirstName%% </u>,
                              <u>%%LastName%% </u>,<u>%%Email%% </u>,
                              <u>%%ActivationCode%% </u>
                            </p>
                          )}
                          {(TEMPPLATETYPE.CLINICIANAPPLICATION === type ||
                            TEMPPLATETYPE.CLINICIANSTATUS === type) && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided variables
                              (specifically valid for this template) <br />
                              <u>%%FirstName%% </u>,<u>%%LastName%% </u>
                            </p>
                          )}
                          {TEMPPLATETYPE.WEBSITEENQUIRYCLIENT === type && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided key to fetch data
                              from enquiry form (specifically valid for this
                              template) <br />
                              <u>%%FirstName%% </u>
                            </p>
                          )}
                          {TEMPPLATETYPE.WEBSITEENQUIRYADMIN === type && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided key to fetch data
                              from enquiry form (specifically valid for this
                              template) <br />
                              <u>%%FirstName%% </u>,<u>%%Email%% </u>,
                              <u>%%State%% </u>,<u>%%Country%% </u>,
                              <u>%%Message%% </u>
                            </p>
                          )}
                          {TEMPPLATETYPE.NEWADMINADDEDCLIENT === type && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided variables to fetch
                              data from add admin form (specifically valid for
                              this template) <br />
                              <u>%%FirstName%% </u>,<u>%%LastName%% </u>,
                              <u>%%Email%% </u>,<u>%%Password%% </u>
                            </p>
                          )}
                          {TEMPPLATETYPE.CLINICIANPURCHASEORDER === type && (
                            <p
                              className="text-info"
                              style={{ fontSize: "13px" }}
                            >
                              ** Kindly utilize the provided variables
                              (specifically valid for this template) <br />
                              <u>%%ClinicianName%% </u>,<u>%%FirstName%% </u>,
                              <u>%%LastName%% </u>,<u>%%Email%% </u>,
                              <u>%%ActivationCode%% </u>,
                              <u>%%PAYMENTOPTION%% </u>,<u>%%WHOLESALE%% </u>,
                              <u>%%UPFRONT%% </u>,<u>%%PURCHASE%% </u>
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="col-3">
                        <div className="signInButton mt-3 mt-md-4">
                          <button className="signinBtn" onClick={handleUpdate}>
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditTemplate;
