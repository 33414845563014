import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import { Link } from "react-router-dom";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
import CoverImage from "../../../components/getCoverImage/CoverImage";
import Footer from "../../../components/frontendComponents/footer/Footer";
import Loader from "../../loader/Loader";
import testimonialimg from "../../../assets/common/img/default.svg";

const BlogListChange = () => {
  const scrollContainer = useRef(null);
  const [jsonData, setJsonData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileBlogRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.BLOGFILENAME
      );
      const jsonFileTestiRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const urlBlog = await getDownloadURL(jsonFileBlogRef);
        const urlTesti = await getDownloadURL(jsonFileTestiRef);

        const responseBlog = await fetch(urlBlog);
        const responseTesti = await fetch(urlTesti);
        const dataBlog = await responseBlog.json();
        const dataTesti = await responseTesti.json();
        const mergedData = [...dataBlog, ...dataTesti];
        setJsonData(mergedData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="min-h-screen bg-gray-50" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />

      {/* Hero Section */}
      <div className="w-full bg-white pt-32">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
          <div className="space-y-4">
            {/* Blog Pill */}
            <div className="inline-flex items-center px-4 py-2 rounded-full bg-blue-100 text-primary">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4 mr-2"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
              </svg>
              <span className="font-medium">Blog</span>
            </div>

            {/* Hero Heading */}
            <h1 className="text-5xl font-bold text-gray-900">
              Neurophysiology: Tinnitus & Hyperacusis
            </h1>
          </div>
        </div>
      </div>

      {/* Blog List Section */}
      <div className="w-full py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {isLoading && <Loader />}

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {!isLoading &&
              jsonData &&
              jsonData.map((item, index) => (
                <div key={index} className="flex flex-col h-full">
                  <div className="bg-white rounded-lg overflow-hidden">
                    {/* Image Section */}
                    <div className="relative">
                      <div className="aspect-w-16 aspect-h-9">
                        {item.cover_img ? (
                          <CoverImage
                            image={item.cover_img}
                            className="object-cover w-full h-full"
                          />
                        ) : (
                          <img
                            src={testimonialimg}
                            alt={item.name}
                            className="object-cover w-full h-full"
                          />
                        )}
                      </div>
                      {/* Author Section */}
                      <div className="absolute bottom-0 left-0 right-0 bg-gradient-to-t from-black/60 to-transparent p-4">
                        <p className="text-white text-sm">
                          Posted By: {item.author ?? item.name}
                        </p>
                      </div>
                    </div>

                    {/* Content Section */}
                    <div className="p-6 flex flex-col items-center">
                      <h2 className="text-xl font-semibold text-gray-900 text-center mb-6">
                        {item.title}
                      </h2>

                      <Link
                        to={FRONTEND_ROUTES.BLOG_DETAILS + "/" + item.slug}
                        className="inline-flex items-center justify-center px-6 py-2 border border-transparent text-base font-medium rounded-md text-white bg-primary transition-colors duration-200"
                      >
                        Read More
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default BlogListChange;
