import React, { useEffect, useState } from 'react'
import { db } from '../../common/firebase';
import { doc, updateDoc } from "firebase/firestore";
import { getAuthDetails, getUserName } from '../../common/helper';
import closeEye from '../../assets/admin/img/closeeye.svg'
import eye from '../../assets/admin/img/eye.svg'
import AdimnSidebar from '../../components/adminSidebar/AdminSidebar'
import AdminHeader from '../../components/adminHeader/AdminHeader'
import Alert from '../../components/alert/Alert';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from 'firebase/auth';
import { DATABASECOLLECTION } from '../../constants/DatabaseConst';

function Profile() {
  const [activeTab, setActiveTab] = useState('editProfile')
  const [errorMsg, setErrorMsg] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [clinicianNameLetter, setClinicianNameLetter] = useState();
  const [clinicianName, setClinicianName] = useState();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errorPassMsg, setErrorPassMsg] = useState(null);
  const [successPassMsg, setSuccessPassMsg] = useState(null);

  const handleOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [profileDetails, setProfileDetails] = useState({
    firstName: '',
    lastName: '',
    phone: '',
    qualifications: '',
    state: '',
    country: '',
    message: '',
  });
  const [profileId, setProfileId] = useState();
  // console.log({ profileDetails })

  useEffect(() => {
    const checkProfileDetails = async () => {
      const login_email = localStorage.getItem('login_email');
      const { docData } = await getAuthDetails(login_email);
      setErrorMsg('');
      // console.log({ docId })
      setProfileId(localStorage.getItem('docid'));
      setProfileDetails(docData);
    }
    checkProfileDetails();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const docData = await getUserName(localStorage.getItem('login_email'));
      docData && setClinicianNameLetter(docData.substring(0, 2).toUpperCase());
      setClinicianName(docData);
    };
    fetchData();
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');
    try {
      const validationRules = [
        { field: profileDetails.firstName, message: 'The first name field must not be left blank.' },
        { field: profileDetails.lastName, message: 'The last name field must not be left blank.' },
        { field: profileDetails.phone, message: 'The phone field must not be left blank.' },
        { field: profileDetails.state, message: 'The state field must not be left blank.' },
        { field: profileDetails.country, message: 'The country field must not be left blank.' },
      ];

      for (const rule of validationRules) {
        if (!rule.field) {
          setErrorMsg(rule.message);
          return false;
        }
      }

      try {
        const docRef = doc(db, DATABASECOLLECTION.USERPROFILES, profileId);
        await updateDoc(docRef, profileDetails);

        setSuccessMsg('Profile updated successfully');
        localStorage.setItem('login_name', profileDetails.firstName + ' ' + profileDetails.lastName);
      } catch (error) {
        console.error("Error adding document: ", error);
      }

    } catch (error) {
      setErrorMsg("Authentication failed: " + error.message);
    }
  };

  const handleChangePassword = async () => {
    const auth = getAuth();
    const user = auth.currentUser;
    setErrorPassMsg('')
    setSuccessPassMsg('');

    if (!user) {
      setErrorPassMsg('No user is currently signed in.');
      return;
    }

    if (!currentPassword || !newPassword || !confirmPassword) {
      setErrorPassMsg('Please fill in all fields.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setErrorPassMsg('New password and confirm password do not match.');
      return;
    }

    const credential = EmailAuthProvider.credential(user.email, currentPassword);
    try {
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setSuccessPassMsg('Password updated successfully!');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (error) {
      setErrorPassMsg(`Error changing password: ${error.message}`);
    }
  };

  return (
    <>
      <div className="dashboardWrapper">
        <AdimnSidebar />
        <div className="adminDashBoard">
          <AdminHeader clinicianNameLetterUpdated={clinicianNameLetter} clinicianNameUpdated={clinicianName} />
          <div className="adminBody">
            <div className="changePass">
              <div className="container-fluid commonCard">
                <div className="container">
                  <ul className="nav nav-tabs changeNav " id="myTab" role="tablist">
                    <li className="nav-item changeNavItem" role="presentation">
                      <button className={`nav-link changeNavLink ${activeTab === 'editProfile' ? 'active' : ''}`} onClick={() => setActiveTab('editProfile')}>Personal Information</button>
                    </li>
                    <li className="nav-item changeNavItem" role="presentation">
                      <button className={`nav-link changeNavLink ${activeTab === 'resetPassword' ? 'active' : ''}`} onClick={() => setActiveTab('resetPassword')}>Change Password</button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    {
                      activeTab === 'editProfile' ?
                        <>
                          <div className="tab-pane fade show active" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                            <div className="container-flui editProfile py-3 py-sm-4 py-lg-5">
                              <div className="containe ">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="profile pb-3 pb-sm-4 pb-lg-5 text-center">
                                      <div className="profDiv">
                                        <div className="profImg">
                                          <div className="profTxt">
                                            {clinicianNameLetter}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {errorMsg && (
                                    <Alert text={errorMsg} type="error" />
                                  )}
                                  {successMsg && (
                                    <Alert text={successMsg} type="success" />
                                  )}
                                  <form className="row" onSubmit={handleSubmit}>
                                    <div className="col-12">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="email"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="email"
                                          name="email"
                                          placeholder="name@example.com"
                                          value={profileDetails.emailAddress}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, email: e.target.value })}
                                          readOnly
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Email
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input type="text"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="firstName"
                                          name="firstName"
                                          placeholder=""
                                          value={profileDetails.firstName}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, firstName: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            First Name
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="lastname"
                                          name="lastname"
                                          placeholder=""
                                          value={profileDetails.lastName}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, lastName: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Last Name
                                          </div>
                                        </label>
                                      </div>
                                    </div>

                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="tel"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="phone"
                                          name="phone"
                                          placeholder=""
                                          value={profileDetails.phone}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, phone: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Phone Number
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="qualifications"
                                          name="qualifications"
                                          placeholder=""
                                          value={profileDetails.qualifications}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, qualifications: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Qualifications
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="state"
                                          name="state"
                                          placeholder=""
                                          value={profileDetails.state}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, state: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            State/ Province
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12 col-lg-6">
                                      <div className="form-floating mb-3">
                                        <input
                                          type="text"
                                          className="form-control shadow-none border formcontrolCustom"
                                          id="country"
                                          name="country"
                                          placeholder=""
                                          value={profileDetails.country}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, country: e.target.value })}
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Country
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="form-floating mb-3 ">
                                        <textarea
                                          className="form-control formcontroltxtCustom shadow-none"
                                          rows="3" placeholder="Leave a comment here"
                                          name="message"
                                          id="message"
                                          value={profileDetails.message}
                                          onChange={(e) => setProfileDetails({ ...profileDetails, message: e.target.value })}
                                        ></textarea>
                                        <label htmlFor="message">
                                          <div className="labelTxt">
                                            Message  (Optional)
                                          </div>
                                        </label>
                                      </div>
                                    </div>
                                    <div className="row mx-0">
                                      <div className="col-12 col-lg-6 col-xl-3 col-xxl-2 px-0">
                                        <div className="signInButton mt-3 mt-md-4 text-center">
                                          <button className='signinBtn' type='submit'>Update Changes</button>
                                        </div>
                                      </div>
                                      {/* <div className="col-12 col-lg-6 col-xl-2">
                                        <div className="signInButton mt-3 mt-md-4 text-center">
                                          <button className='signinBtn cancelColor' type='reset'>Cancel</button>
                                        </div>
                                      </div> */}
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </> : <>
                          <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                            <div className="container-fluid changePassSec px-0">
                              <div className="container changeContainer py-3 py-sm-4 py-lg-5 px-0">
                                <div className="row mx-0">
                                  <div className="col-12 px-0">
                                    <div className="inputSide">
                                      {errorPassMsg && (
                                        <Alert text={errorPassMsg} type="error" />
                                      )}
                                      {successPassMsg && (
                                        <Alert text={successPassMsg} type="success" />
                                      )}
                                      <div className="form-floating formFloating position-relative mb-3">
                                        <input
                                          type={showOldPassword ? 'text' : 'password'}
                                          value={currentPassword}
                                          className='form-control shadow-none border formcontrolCustom'
                                          onChange={(e) => setCurrentPassword(e.target.value)}
                                          autoComplete='off'

                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Old Password
                                          </div>
                                        </label>
                                        <span className="input-grouptext eyeImg" onClick={handleOldPassword}>
                                          <div className="eyeImgDiv">
                                            {showOldPassword ?
                                              <img src={eye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                              :
                                              <img src={closeEye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                            }
                                          </div>
                                        </span>
                                      </div>
                                      <div className="form-floating formFloating position-relative mb-3">
                                        <input
                                          type={showNewPassword ? 'text' : 'password'}
                                          value={newPassword}
                                          className='form-control shadow-none border formcontrolCustom'
                                          onChange={(e) => setNewPassword(e.target.value)}
                                          autoComplete='off'
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            New Password
                                          </div>
                                        </label>
                                        <span className="input-grouptext eyeImg" onClick={handleNewPassword}>
                                          <div className="eyeImgDiv">
                                            {showNewPassword ?
                                              <img src={eye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                              :
                                              <img src={closeEye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                            }
                                          </div>
                                        </span>
                                      </div>
                                      <div className="form-floating formFloating position-relative">
                                        <input
                                          type={showConfirmPassword ? 'text' : 'password'}
                                          value={confirmPassword}
                                          className='form-control shadow-none border formcontrolCustom'
                                          onChange={(e) => setConfirmPassword(e.target.value)}
                                          autoComplete='off'
                                        />
                                        <label htmlFor="floatingInput" className='formControlLable'>
                                          <div className="labelTxt">
                                            Confirm Password
                                          </div>
                                        </label>
                                        <span className="input-grouptext eyeImg" onClick={handleConfirmPassword}>
                                          <div className="eyeImgDiv">
                                            {showConfirmPassword ?
                                              <img src={eye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                              :
                                              <img src={closeEye} className="img-fluid w-100 h-100" alt="tableAccordion" />
                                            }
                                          </div>
                                        </span>
                                      </div>
                                      <div className="row mx-0">
                                        <div className="col-12 col-lg-6 col-xl-3 col-xxl-2 px-0">
                                          <div className="signInButton mt-3 mt-md-5 text-center">
                                            <button className='signinBtn' type='btn' onClick={handleChangePassword} >Update Changes</button>
                                          </div>
                                        </div>
                                        {/* <div className="col-12 col-lg-6 col-xl-2">
                                          <div className="signInButton mt-3 mt-md-5 text-center">
                                            <button className='signinBtn cancelColor' type='btn'>Cancel</button>
                                          </div>
                                        </div> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                    }
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div >
    </>
  )
}

export default Profile