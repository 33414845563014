import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDown, Instagram, Twitter, Facebook } from "lucide-react";
import appStoreImage from "../../../assets/common/img/app-white.png";
import { FRONTEND_ROUTES } from "../../../constants/RouteConst";
// import logoColoured from "../../../assets/common/img/nuroColur.png";
// import { FRONTEND_ROUTES } from "../../../constants/RouteConst";

const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative w-1/2">
      <select
        value={isOpen ? "open" : ""}
        onChange={() => setIsOpen(!isOpen)}
        className="appearance-none w-full flex items-center gap-2 px-6 py-2 rounded-full border border-gray-300 border-solid hover:border-primary transition-colors text-gray-700 bg-white cursor-pointer"
      >
        <option value="">English</option>
        <option value="">Spanish</option>
      </select>
      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4">
        <ChevronDown
          size={16}
          className={`text-gray-500 transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 mt-2 w-full bg-white rounded-full shadow-lg border border-gray-100">
          <button
            className="w-full px-6 py-2 text-left hover:bg-gray-50 text-gray-700 first:rounded-t-full"
            onClick={() => setIsOpen(false)}
          >
            English
          </button>
          <button
            className="w-full px-6 py-2 text-left hover:bg-gray-50 text-gray-700 last:rounded-b-full"
            onClick={() => setIsOpen(false)}
          >
            Spanish
          </button>
        </div>
      )}
    </div>
  );
};

const Footer = () => {
  return (
    <footer className="bg-white py-16">
      <div className="max-w-7xl mx-auto px-6">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-12 gap-8 md:gap-12 mb-12">
          {/* Language Selector */}
          <div className="md:col-span-6">
            <LanguageSelector />
            <div className="mt-6 pl-2">
              <a
                href="tel:+17208193700"
                className="text-gray-600 text-base mb-4 hover:text-primary transition-colors"
              >
                Tel: +1 720 819 3700
              </a>
              <p className="text-gray-600 text-base mt-6">
                © 2024 SoundVida Inc. All rights reserved.
              </p>
            </div>

            <div className="flex gap-6 mt-8">
              <a
                href="https://instagram.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-gray-900 transition-colors p-2"
              >
                <Instagram size={32} />
              </a>
              <a
                href="https://twitter.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-gray-900 transition-colors p-2"
              >
                <Twitter size={32} />
              </a>
              <a
                href="https://spotify.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-gray-900 transition-colors p-2"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-8 h-8"
                >
                  <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z" />
                </svg>
              </a>
              <a
                href="https://facebook.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-gray-900 transition-colors p-2"
              >
                <Facebook size={32} />
              </a>
              <a
                href="https://tiktok.com"
                target="_blank"
                rel="noopener noreferrer"
                className="text-primary hover:text-gray-900 transition-colors p-2"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  className="w-8 h-8"
                >
                  <path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z" />
                </svg>
              </a>
            </div>
          </div>

          {/* Company Links */}
          <div className="md:col-span-2">
            <h3 className="text-[#1f2937] font-semibold mb-4 text-base">
              COMPANY
            </h3>
            <ul className="space-y-3">
              <li>
                <Link
                  to="/about"
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to={FRONTEND_ROUTES.JOURNALS}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Resources
                </Link>
              </li>
              <li>
                <Link
                  to={FRONTEND_ROUTES.START_YOUR_JOURNEY}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Contact Us
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  to="/privacy-policy"
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Terms & Conditions
                </Link>
              </li>
            </ul>
          </div>

          {/* Resources Links */}
          <div className="md:col-span-2">
            <h3 className="text-[#1f2937] font-semibold mb-4 text-base">
              RESOURCES
            </h3>
            <ul className="space-y-3">
              <li>
                <Link
                  to={FRONTEND_ROUTES.BLOGS}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to={FRONTEND_ROUTES.TINNITUS}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  What is Tinnitus?
                </Link>
              </li>
              <li>
                <Link
                  to={FRONTEND_ROUTES.FAQ}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Frequently asked Questions
                </Link>
              </li>
              <li>
                <Link
                  to="/neuromonics"
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  For Providers
                </Link>
              </li>
              <li>
                <Link
                  to={FRONTEND_ROUTES.FINDCLINIC}
                  className="text-gray-600 hover:text-gray-900 text-sm no-underline"
                >
                  Find a Clinician
                </Link>
              </li>
            </ul>
          </div>

          {/* App Download */}
          <div className="md:col-span-2">
            <h3 className="text-[#1f2937] font-semibold mb-4 text-base">
              Get the App
            </h3>
            <a
              href="https://apps.apple.com/us/app/neuromonics-tinnitus-treatment/id6470243215"
              className="inline-block hover:opacity-80 transition-opacity"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={appStoreImage}
                alt="Download on the App Store"
                className="h-10"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
