import React, { useRef } from "react";
import Header from "../../../components/frontendComponents/header/Header";
const RequirementItem = ({ number, title, description }) => (
  <div className="flex flex-col md:flex-row mb-10 items-start gap-2">
    <div className="text-[#2d4b6d] text-3xl font-medium w-[40px] md:w-[40px] shrink-0">
      {number}
    </div>
    <div className="text-[#2d4b6d] text-xl font-medium w-[240px] md:w-[300px] shrink-0">
      {title}
    </div>
    <p className="text-[#65758B] text-base ">{description}</p>
  </div>
);

const PricingCard = ({
  isMonthly,
  price,
  period,
  features,
  buttonText,
  footerText,
  discount,
}) => (
  <div className="bg-white rounded-xl p-8 shadow-2xl flex flex-col">
    <h3 className="text-xl font-medium text-center mb-4">
      {isMonthly ? "Monthly" : "Yearly"} Subscription
    </h3>
    <div className="flex items-center justify-center gap-1 mb-6">
      <span className="text-2xl font-bold">${price}</span>
      <span className="text-gray-600">/{period}</span>
      {discount && (
        <span className="ml-2 bg-primary text-gray-200 text-sm px-2 py-1 rounded">
          {discount}
        </span>
      )}
    </div>
    <div className="mb-8">
      <p className="text-gray-700 mb-4">Your premium membership includes</p>
      <ul className="space-y-3">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center gap-2">
            <div className="w-2 h-2 rounded-full bg-blue-600" />
            <span className="text-gray-700">{feature}</span>
          </li>
        ))}
      </ul>
    </div>
    <div className="mt-auto">
      <p className="text-xs text-gray-500 mb-4 leading-relaxed">{footerText}</p>
      <button className="w-full bg-primary hover:bg-[#1a3775] text-white py-3 rounded-md transition-colors">
        {buttonText}
      </button>
    </div>
  </div>
);

const Membership = () => {
  const scrollContainer = useRef(null);

  const requirements = [
    {
      number: "1",
      title: "Audiogram",
      description:
        "You will need to give your assigned telehealth clinician your most recent audiogram.",
    },
    {
      number: "2",
      title: "High Quality Headphones",
      description: "Noise Cancellation should remain off during the treatment",
    },
    {
      number: "3",
      title: "Apple Iphone or Ipad",
      description: "System Requirement: iOS 17.0 or newer is required",
    },
  ];

  const monthlyFeatures = [
    "Unlimited access to content",
    "1 on 1 guided clinician support for 6 months",
    "Unlimited Customised",
  ];

  const yearlyFeatures = [
    "Unlimited access to content",
    "1 on 1 guided clinician support for 6",
    "Unlimited Customised",
  ];

  return (
    <div className="min-h-screen bg-white" ref={scrollContainer}>
      <Header scrollContainer={scrollContainer} secondHeader={true} />
      {/* Requirements Section */}
      <section className="container mx-auto px-4 py-16 max-w-5xl mt-16">
        <h2 className="text-3xl text-primary font-medium mb-16">
          Things You will need to get started
        </h2>
        <div className="bg-white p-8 rounded-lg">
          {requirements.map((req) => (
            <RequirementItem key={req.number} {...req} />
          ))}
        </div>
      </section>

      {/* Pricing Section */}
      <section className="bg-white py-16">
        <div className="container mx-auto px-4 max-w-5xl">
          <h2 className="text-5xl text-primary font-medium text-center mb-8 border-2 border-primary rounded-lg">
            Premium Membership
          </h2>
          <h3 className="text-primary text-xl text-center mb-12">
            Choose Your Subscription
          </h3>

          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <PricingCard
              isMonthly={true}
              price="289.99"
              period="month"
              features={monthlyFeatures}
              buttonText="Select Monthly Plan"
              footerText="After 6 months of premium membership you will be billed for the management plan subscription at $49.99 USD which automatically renews each month."
            />

            <PricingCard
              isMonthly={false}
              price="1599.99"
              period="year"
              discount="-16%"
              features={yearlyFeatures}
              buttonText="Select Yearly Plan"
              footerText="After 12 months you will be billed for the management plan subscription at $49.99 USD which automatically renews each month."
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Membership;
