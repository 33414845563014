import React from "react";
import successRight from "../../assets/admin/img/rightSuccess.svg";
import RedCross from "../../assets/admin/img/Redcross.svg";

const Alert = ({ text, type }) => {
  return (
    <div className="py-3">
      {type === "success" ? (
        <div className="flex items-center">
          <div className="w-full">
            <div className="rounded-lg border border-[#a3cfbb] bg-[#d1e7dd] shadow-sm">
              <div className="flex items-center p-3">
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="mr-2 pl-1">
                      <div className="h-4 w-4">
                        <img
                          src={successRight}
                          className="h-full w-full"
                          alt="success"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-left">
                        <p className="text-sm text-black/80">{text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Uncomment if you want to add close button
                <div className="pl-4">
                  <button className="text-sm text-black/90 hover:text-black">
                    Close
                  </button>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center py-5">
          <div className="w-full">
            <div className="rounded-lg border border-[#f1aeb5] bg-[#f8d7da] shadow-sm">
              <div className="flex items-center p-3">
                <div className="flex-1">
                  <div className="flex items-center">
                    <div className="mr-2 pl-1">
                      <div className="h-4 w-4">
                        <img
                          src={RedCross}
                          className="h-full w-full"
                          alt="error"
                        />
                      </div>
                    </div>
                    <div className="flex-1">
                      <div className="text-left">
                        <p className="text-sm text-black/80">{text}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Uncomment if you want to add close button
                <div className="pl-4">
                  <button className="text-sm text-black/90 hover:text-black">
                    Close
                  </button>
                </div>
                */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Alert;
