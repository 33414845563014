import React from "react";
import { Link } from "react-router-dom";

const FooterCTA = () => {
  return (
    <section className="bg-primary py-8 md:py-12 rounded-lg mx-4">
      <div className="px-20 container">
        <div className="max-w-3xl space-y-8">
          <h2 className="text-warning text-2xl md:text-4xl font-semibold">
            Sound Vitamins
          </h2>
          <p className="text-[#deebec] text-base md:text-lg">
            We put vitamins into sound specifically tailored to you.
          </p>
          <div className="flex flex-col sm:flex-row justify-start gap-4">
            <Link
              to="/start-your-journey"
              className="px-6 md:px-8 py-3 bg-secondary text-primary rounded-xl font-semibold hover:bg-gray-200 transition-colors no-underline text-center"
            >
              Start Your Journey
            </Link>
            <Link
              to="/neuromonics"
              className="px-6 md:px-8 py-3 border border-secondary text-white rounded-xl font-semibold hover:bg-gray-200 hover:text-primary transition-colors no-underline text-center"
            >
              For Providers
            </Link>
          </div>

          {/* Optional: Add decorative elements */}
          <div className="hidden md:block absolute top-1/2 left-0 transform -translate-y-1/2">
            <div className="w-32 h-32 bg-white/5 rounded-full blur-xl"></div>
          </div>
          <div className="hidden md:block absolute top-1/2 right-0 transform -translate-y-1/2">
            <div className="w-32 h-32 bg-white/5 rounded-full blur-xl"></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterCTA;
