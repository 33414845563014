import React, { useEffect, useRef, useState } from "react";
import Header from "../../../components/frontendComponents/header/Header";
import { Link } from "react-router-dom";
// import { FRONTEND_ROUTES } from '../../../constants/RouteConst';
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "../../../common/firebase";
import { FILENAME, STORAGE, STORAGEURL } from "../../../constants/StorageConst";
// import CoverImage from '../../../components/getCoverImage/CoverImage';
import five from "../../../assets/common/img/five.jpg";
import Footer from "../../../components/frontendComponents/footer/Footer";
// import Loader from '../../loader/Loader';
// import testimonialimg from '../../../assets/common/img/default.svg';

const BlogListChange = () => {
  const scrollContainer = useRef(null);
  const [, setJsonDataFirst] = useState(null);
  const [, setJsonDataSecond] = useState(null);
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const jsonFileBlogRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.BLOGFILENAME
      );
      const jsonFileTestiRef = ref(
        storage,
        STORAGEURL + "/" + STORAGE.BLOGS + "/" + FILENAME.TESTIMONIALSFILE
      );
      try {
        const urlBlog = await getDownloadURL(jsonFileBlogRef);
        const urlTesti = await getDownloadURL(jsonFileTestiRef);
        const responseBlog = await fetch(urlBlog);
        const responseTesti = await fetch(urlTesti);
        const dataBlog = await responseBlog.json();
        const dataTesti = await responseTesti.json();
        const mergedData = [...dataBlog, ...dataTesti];
        const jsonDataLength = mergedData.length;
        if (jsonDataLength % 2 === 0) {
          const halfLength = jsonDataLength / 2;
          const firstHalfData = mergedData.slice(0, halfLength);
          const secondHalfData = mergedData.slice(halfLength);
          setJsonDataFirst(firstHalfData);
          setJsonDataSecond(secondHalfData);
        } else {
          const greaterData = mergedData.slice(0, (jsonDataLength - 1) / 2 + 1);
          const lesserData = mergedData.slice((jsonDataLength - 1) / 2 + 1);
          setJsonDataFirst(greaterData);
          setJsonDataSecond(lesserData);
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching or parsing JSON file:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <div className="frontendOuter paddingTopHeader" ref={scrollContainer}>
        <Header scrollContainer={scrollContainer} secondHeader={true} />
        <div className="container-fluid blogFluid mt-5">
          <div className="container">
            <div className="row heroBanner mx-0">
              <div className="col-12">
                <div className="row mx-0 mb-4">
                  <div className="col-auto px-0">
                    <div className="heroPill">
                      <svg
                        stroke="currentColor"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M5 16V9h14V2H5l14 14h-7m-7 0l7 7v-7m-7 0h7"></path>
                      </svg>
                      Blog
                    </div>
                  </div>
                  <div className="col-12 px-0">
                    <div className="row mx-0">
                      <div className="col px-0">
                        <div className="heroHeading">
                          Neurophysiology: Tinnitus & Hyperacusis
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid blogInnerFluid pb-5">
          <div className="container">
            <div className="row gx-lg-5 gx-0 paddingInline">
              <div className="col-md-4 sm-12">
                <div className="row h-100 flex-column justify-content-start">
                  <div className="col-12">
                    <div className="blogCradWrapper">
                      <div className="row mx-0">
                        <div className="col-12">
                          <div className="blogImg one blogImg2">
                            <img src={five} alt="" />
                            <div className="row mx-0 align-items-center authorRow">
                              <div className="col-12 px-0">
                                <div className="authorList">
                                  Posted By: Mukesh Ambani
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-center py-lg-5 py-3">
                            <div className="col-auto d-flex gap-1"></div>
                            <div className="col-12 d-flex justify-content-center">
                              <div className="title">kjbvfvfu</div>
                            </div>
                            <div className="col-12 d-flex justify-content-center ">
                              <Link className="nuroBtnn text-decoration-none">
                                Read More{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sm-12">
                <div className="row h-100 flex-column justify-content-start">
                  <div className="col-12">
                    <div className="blogCradWrapper">
                      <div className="row mx-0">
                        <div className="col-12">
                          <div className="blogImg one blogImg2">
                            <img src={five} alt="" />
                            <div className="row mx-0 align-items-center authorRow">
                              <div className="col-12 px-0">
                                <div className="authorList">
                                  Posted By: Mukesh Ambani
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-center py-lg-5 py-3">
                            <div className="col-auto d-flex gap-1"></div>
                            <div className="col-12 d-flex justify-content-center">
                              <div className="title">kjbvfvfu</div>
                            </div>
                            <div className="col-12 d-flex justify-content-center ">
                              <Link className="nuroBtnn text-decoration-none">
                                Read More{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sm-12">
                <div className="row h-100 flex-column justify-content-start">
                  <div className="col-12">
                    <div className="blogCradWrapper">
                      <div className="row mx-0">
                        <div className="col-12">
                          <div className="blogImg one blogImg2">
                            <img src={five} alt="" />
                            <div className="row mx-0 align-items-center authorRow">
                              <div className="col-12 px-0">
                                <div className="authorList">
                                  Posted By: Mukesh Ambani
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-center py-lg-5 py-3">
                            <div className="col-auto d-flex gap-1"></div>
                            <div className="col-12 d-flex justify-content-center">
                              <div className="title">kjbvfvfu</div>
                            </div>
                            <div className="col-12 d-flex justify-content-center ">
                              <Link className="nuroBtnn text-decoration-none">
                                Read More{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 sm-12">
                <div className="row h-100 flex-column justify-content-start">
                  <div className="col-12">
                    <div className="blogCradWrapper">
                      <div className="row mx-0">
                        <div className="col-12">
                          <div className="blogImg one blogImg2">
                            <img src={five} alt="" />
                            <div className="row mx-0 align-items-center authorRow">
                              <div className="col-12 px-0">
                                <div className="authorList">
                                  Posted By: Mukesh Ambani
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="row justify-content-center py-lg-5 py-3">
                            <div className="col-auto d-flex gap-1"></div>
                            <div className="col-12 d-flex justify-content-center">
                              <div className="title">kjbvfvfu</div>
                            </div>
                            <div className="col-12 d-flex justify-content-center ">
                              <Link className="nuroBtnn text-decoration-none">
                                Read More{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default BlogListChange;
