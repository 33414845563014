import React from "react";
// import loader from '../../assets/admin/img/loader.gif'

const Loader = ({ isEnquieySpinner }) => {
  return (
    <>
      <div className={`loaderPage ${isEnquieySpinner ? "h-100" : "vh-100"}`}>
        <div className="container-fluid h-100">
          <div className="row justify-content-center align-items-center h-100">
            <div className="col-auto px-0">
              <div className="preloader">
                <div className="spinner"></div>
                <div className="mt-1 text-nowrap">Please wait...</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loader;
